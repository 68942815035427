/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Agroevidence API
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
export type EvictParcelChainCacheEventParams = {
farmIds?: string;
};

export type ResetSeasonParams = {
farmIds: string;
};

export type GetActionTypesParams = {
language?: string;
group?: ActionTypeGroupTo;
};

export type TargetSeedApplicationApplicationsParams = {
farmIds: string;
'target-crop-id': number;
'action-date': string;
'parcel-ids': number[];
'except-action-id'?: number;
language: LocalLanguage;
};

export type DeleteActionParams = {
farmIds: string;
};

export type GetAction200 = ActionEPHDetailTo | ActionOtherDetailTo | ActionRevenueDetailTo | ActionSowingDetailTo;

export type GetActionParams = {
farmIds: string;
language?: string;
};

export type DeleteActionsParams = {
actionIds: number[];
farmIds: string;
};

export type GetActionsParams = {
farmIds: string;
language?: string;
search?: string;
parcelIds?: number[];
parcels?: number[];
from?: string;
'date-to'?: string;
cropId?: number;
'target-crop-ids'?: number[];
materialType?: MaterialType;
seedApplicationIds?: number[];
type?: ActionType[];
materialIds?: number[];
sources?: ActionSource[];
'is-draft'?: boolean;
'with-fertilizer-detail'?: boolean;
pageOffset?: number;
pageSize?: number;
sortProperty?: string;
};

export type GetFullLpisDetailParams = {
validFrom?: string;
language?: string;
};

export type GetAllFarmUsersParams = {
name?: string;
};

export type GetAllFarmsParams = {
name?: string;
search?: string;
pageOffset?: number;
pageSize?: number;
sortProperty?: string;
language?: string;
};

export type GetCropsParams = {
farmIds: string;
language?: string;
pageOffset?: number;
pageSize?: number;
sortProperty?: string;
/**
 * Filter by crop name
 */
search?: string;
/**
 * Filter by crop external id
 */
'external-ids'?: string[];
/**
 * Filter by crop validity on date
 */
'valid-on'?: string;
/**
 * Include no crop
 */
'include-no-crop'?: boolean;
};

export type GetCropUseTypesParams = {
'crop-external-id'?: number;
};

export type GetFertilizerNitrogenCategoryParams = {
language?: LocalLanguage;
};

export type GetFertilizerRegistratinTypeParams = {
language?: LocalLanguage;
};

export type GetHarvestProductsParams = {
'crop-external-id'?: number;
};

export type GetFertilizerSuggestionsParams = {
farmIds: string;
/**
 * Filter by fertilizer name
 */
search?: string;
/**
 * Set catalogue type
 */
catalogue?: CatalogueType;
/**
 * Include hidden fertilizers
 */
'include-hidden'?: boolean;
/**
 * Filter by fertilizer external id
 */
'external-ids'?: string[];
/**
 * Filter by fertilizer organic flag
 */
organic?: boolean;
/**
 * Filter by eAgri fertilizer nitrogen category type
 */
'eagri-nitrogen-category'?: EagriFertilizerNitrogenCategory[];
/**
 * Fertilizer validity end
 */
'valid-on'?: string;
/**
 * If true, returns only favorite fertilizers
 */
'favorite-only'?: boolean;
};

export type GetFertilizerMineralIntake1Params = {
farmIds: string;
unitId: string;
dose: number;
};

export type GetBioFunctionParams = {
search?: string;
};

export type GetPlantProtectionsParams = {
farmIds: string;
language?: string;
/**
 * Filter by plant protection product name
 */
search?: string;
/**
 * Set of catalogue ids
 */
catalogue?: string[];
/**
 * Include hidden plant protection products
 */
'include-hidden'?: boolean;
/**
 * Plant protection usability end
 */
'usable-until'?: string;
/**
 * If true, returns only favorite plant protection products
 */
'favorite-only'?: boolean;
'crop-id'?: string[];
'organism-id'?: string[];
'active-substance-id'?: string[];
pageOffset?: number;
pageSize?: number;
sortProperty?: string;
};

export type GetPlantProtectionSuggestionsParams = {
farmIds: string;
language?: string;
filter: RestPlantProtectionSuggestionFilter;
pageOffset?: number;
pageSize?: number;
sortProperty?: string;
};

export type GetPlantProtectionApplicationsParams = {
farmIds: string;
language: LocalLanguage;
};

export type GetPlantProtectionDetailApplicationsParams = {
farmIds: string;
language: LocalLanguage;
};

export type GetPlantProtectionDetailParams = {
farmIds: string;
};

export type GetSeedSuggestionsParams = {
farmIds: string;
/**
 * Catalog localization language
 */
language?: string;
/**
 * Filter by crop name
 */
search?: string;
/**
 * Set catalogue type
 */
catalogue?: CatalogueType;
/**
 * Include hidden seeds
 */
'include-hidden'?: boolean;
/**
 * Filter by crop external id
 */
'external-ids'?: string[];
/**
 * Seed validity end
 */
'valid-on'?: string;
/**
 * If true, returns only favorite fertilizers
 */
'favorite-only'?: boolean;
};

export type GetCataloguesParams = {
farmIds: string;
};

export type SearchCountriesParams = {
search?: string;
language?: string;
};

export type GetAccountUserFarmsParams = {
userId: number;
};

export type GetFarmUpdatesParams = {
farmIds: string;
pageOffset?: number;
pageSize?: number;
sortProperty?: string;
language?: string;
};

export type GetCentersParams = {
farmIds: string;
search?: string;
};

export type GetParcelCurrentSeedApplicationColorsParams = {
farmIds: string;
};

export type GetLandUseParams = {
language?: string;
};

export type GetParcelStatisticsParams = {
farmIds: string;
language?: string;
};

export type GetParcelSuggestionsParams = {
farmIds: string;
/**
 * Filter by parcel name or block number.
 */
search?: string;
/**
 * Filter by parcel status.
 */
status?: ParcelStatus[];
/**
 * <p>Start of interval in which the parcel is considered valid.</p><p>If <b>valid-from</b> and/or <b>valid-to</b> filter is used, it will return parcels that are considered valid inside the given date range. If either <b>valid-from</b> or <b>valid-to</b> is null, the interval is considered open from respective side.<p>
 */
'valid-from'?: string;
/**
 * Filter by parcel land use.
 */
'land-use'?: LandUse[];
/**
 * Filter only parcels with given IDs.
 */
ids?: string[];
/**
 * Filter parcels by zone ID(s) of zones they are in. Use NONE to filter parcels with no zone.
 */
zones?: string[];
/**
 * Filter parcels by center ID(s) of centers they are in. Use NONE to filter parcels with no center.
 */
centers?: string[];
/**
 * Filter parcels by sown crop legislative codes.
 */
crops?: number[];
/**
 * Filters parcels by their nitrate vulnerable status.<ul><li>If <b>true</b>, filters only nitrate vulnerable parcels</li><li>If <b>false</b>, filters only nitrate invulnerable parcel</li><li>If <b>null</b>, no nitrate vulnerable filter is applied</li></ul>
 */
'nitrate-vulnerable'?: boolean;
/**
 * Filters parcels by their sown status.<ul><li>If <b>true</b>, filters only sown parcels</li><li>If <b>false</b>, filters only unsown parcel</li><li>If <b>null</b>, no sowning filter is applied</li></ul>
 */
sown?: boolean;
};

export type GetParcelRestrictionsParams = {
farmIds: string;
};

export type GetParcelPredecessorsParams = {
farmIds: string;
language?: string;
};

export type GetSeedApplications1Params = {
farmIds: string;
'exclude-no-crop'?: boolean;
language?: string;
};

export type DeleteParcelSubtractionParams = {
farmIds: string;
type: RestrictionType;
value: number;
};

export type GetParcelSubtractionHistoryParams = {
farmIds: string;
otherParcels: number[];
};

export type GetParcels2Params = {
farmIds: string;
language?: string;
/**
 * Filter by parcel name or block number.
 */
search?: string;
/**
 * Filter by parcel status.
 */
status?: ParcelStatus[];
/**
 * <p>Start of interval in which the parcel is considered valid.</p><p>If <b>valid-from</b> and/or <b>valid-to</b> filter is used, it will return parcels that are considered valid inside the given date range. If either <b>valid-from</b> or <b>valid-to</b> is null, the interval is considered open from respective side.<p>
 */
'valid-from'?: string;
/**
 * Filter by parcel land use.
 */
'land-use'?: LandUse[];
/**
 * Filter only parcels with given IDs.
 */
ids?: string[];
/**
 * Filter parcels by zone ID(s) of zones they are in. Use NONE to filter parcels with no zone.
 */
zones?: string[];
/**
 * Filter parcels by center ID(s) of centers they are in. Use NONE to filter parcels with no center.
 */
centers?: string[];
/**
 * Filter parcels by sown crop legislative codes.
 */
crops?: number[];
/**
 * Filters parcels by their nitrate vulnerable status.<ul><li>If <b>true</b>, filters only nitrate vulnerable parcels</li><li>If <b>false</b>, filters only nitrate invulnerable parcel</li><li>If <b>null</b>, no nitrate vulnerable filter is applied</li></ul>
 */
'nitrate-vulnerable'?: boolean;
/**
 * Filters parcels by their sown status.<ul><li>If <b>true</b>, filters only sown parcels</li><li>If <b>false</b>, filters only unsown parcel</li><li>If <b>null</b>, no sowning filter is applied</li></ul>
 */
sown?: boolean;
pageOffset?: number;
pageSize?: number;
sortProperty?: string;
};

export type GetActionsReportParams = {
farmIds: string;
'parcel-ids'?: number[];
'date-from': string;
'date-to': string;
'file-type': FileType;
language?: string;
};

export type CheckFertilizerUserReportParams = {
farmIds: string;
guid: string;
};

export type GetEagriPorUseErrorsParams = {
farmIds: string;
guid: string;
};

export type GetCvsReportParams = {
farmIds: string;
};

export type GetFarmNitrogenReportParams = {
farmIds: string;
'date-from': string;
'file-type': FileType;
language?: string;
};

export type GetFertilizerConsumptionByCropReportParams = {
farmIds: string;
'parcel-ids'?: number[];
'date-from': string;
'date-to': string;
'file-type': FileType;
language?: string;
};

export type GetFertilizerConsumptionReportParams = {
farmIds: string;
'parcel-ids'?: number[];
'date-from': string;
'date-to': string;
'file-type': FileType;
language?: string;
};

export type GetFertilizerOrganicReportParams = {
farmIds: string;
'parcel-ids'?: number[];
'date-from': string;
'date-to': string;
'file-type': FileType;
language?: string;
};

export type GetFertilizerUsageReportParams = {
farmIds: string;
'parcel-ids'?: number[];
'date-from': string;
'date-to': string;
'file-type': FileType;
language?: string;
};

export type GetReportsParams = {
farmIds: string;
type: ReportType;
pageOffset?: number;
pageSize?: number;
sortProperty?: string;
language?: string;
};

export type GetParcels1Params = {
farmIds: string;
language: LocalLanguage;
};

export type GetPorConsumptionByCropReportParams = {
farmIds: string;
'parcel-ids'?: number[];
'date-from': string;
'date-to': string;
'file-type': FileType;
language?: string;
};

export type GetPorConsumptionByPPReportParams = {
farmIds: string;
'parcel-ids'?: number[];
'date-from': string;
'date-to': string;
'file-type': FileType;
language?: string;
};

export type GetPorUsageReportParams = {
farmIds: string;
'parcel-ids'?: number[];
'date-from': string;
'date-to': string;
'file-type': FileType;
language?: string;
};

export type GetRestrictionsSowingReportParams = {
farmIds: string;
date: string;
'failed-only'?: boolean;
'parcel-ids'?: number[];
seasons?: number;
codes?: string[];
language?: string;
};

export type GetRevenuesReportParams = {
farmIds: string;
'parcel-ids'?: number[];
'date-from': string;
'date-to': string;
'file-type': FileType;
language?: string;
};

export type GetSeedApplicationsReportParams = {
farmIds: string;
'parcel-ids'?: number[];
'date-from': string;
'date-to': string;
'file-type': FileType;
language?: string;
};

export type GetSeededAreasParams = {
farmIds: string;
'parcel-ids'?: number[];
'date-from': string;
'date-to': string;
'file-type': FileType;
language?: string;
};

export type GetSeedApplicationsAtDateParams = {
farmIds: string;
'parcel-ids': number[];
date: string;
locale: LocalLanguage;
};

export type GetSeasonsExcelParams = {
farmIds: string;
startYear: number;
size: number;
language?: LocalLanguage;
'parcel-ids'?: number[];
};

export type GetSowingPlanStatisticsParams = {
farmIds: string;
language?: string;
};

export type GetCropCatalogueTypeParams = {
farmIds: string;
};

export type GetCropInternalParams = {
language?: string;
};

export type GetCropsInternal1Params = {
catalogue: CropCatalogueType;
'external-id'?: number[];
language?: string;
};

export type GetUserFarmsParams = {
'user-id': number;
};

export type GetFarmUsersParams = {
farmIds: string;
};

export type GetFarmDetailParams = {
farmIds: string;
};

export type GetFertilizerMineralIntakeParams = {
farmIds: string;
'unit-id': string;
dose: number;
};

export type GetFertilizersInternalParams = {
farmIds: string;
ids?: number[];
name?: string;
};

export type GetSeedApplicationsParams = {
farmIds: string;
language: LocalLanguage;
};

export type GetParcelDetailParams = {
farmIds: string;
language?: string;
};

export type GetParcelsParams = {
farmIds: string;
language?: string;
ids?: number[];
'valid-from'?: string;
'valid-to'?: string;
search?: string;
'crop-ids'?: number[];
pageOffset?: number;
pageSize?: number;
sortProperty?: string;
};

export type GetParcelSowingPlanParams = {
farmIds: string;
language?: string;
startYear: number;
};

export type GetCropsInternalParams = {
farmIds: string;
ids?: number[];
name?: string;
language?: string;
};

export type GetParcelByArealNameParams = {
farmIds: string;
'zone-name': string;
'parcel-status'?: ParcelStatus;
language: LocalLanguage;
};

export type PatchSeedParams = {
farmIds: string;
language?: string;
};

export type GetSeedParams = {
farmIds: string;
language?: string;
};

export type UpdateZoneParams = {
farmIds: string;
};

export type DeleteZoneParams = {
farmIds: string;
};

export type PatchParcelParams = {
farmIds: string;
};

export type GetParcelParams = {
farmIds: string;
language?: string;
};

export type ValidateEPHActionParams = {
farmIds: string;
};

export type SplitActionParams = {
farmIds: string;
};

export type GetSeedApplicationFertilizationParams = {
farmIds: string;
};

export type FixEagriActionCropsParams = {
farmIds: string[];
};

export type MoveAllInitialSeedAppsToStartParams = {
farmIds?: string[];
};

export type AddSowingPlanToCurrentSeasonParams = {
farmIds?: string[];
};

export type UpdateEagriFarmParams = {
updateMode?: EagriFullFarmUpdateMode;
language?: string;
};

export type GetLpisBlocksParams = {
language?: string;
};

export type MoveActionsAfterUpdateParams = {
farms: number[];
};

export type MoveParcelValiditiesToNewestSowingParams = {
farms: number;
parcels?: number[];
};

export type SatelliteUpdateSyncParams = {
/**
 * 
                    The update date to which the manual synchronisation will be related to.
                    Defaults to now. Will search for the newest update <= update-date.
                    NOTE: If you wish to sync farm for multiple dates, make sure the dates are passed in ascending order!
                
 */
'update-date'?: string;
farms: number;
};

export type UpdateNitrateVulnerableParams = {
batch?: number;
'batch-size'?: number;
};

export type RepairSeedApplicationsParams = {
farms: number[];
};

export type ImportGroundWaterProtectionZonesBody = {
  file: Blob;
};

export type ImportGroundWaterProtectionZonesParams = {
'source-srid': number;
};

export type ImportSurfaceWaterProtectionZonesBody = {
  file: Blob;
};

export type ImportSurfaceWaterProtectionZonesParams = {
'source-srid': number;
};

export type PostFertilizerParams = {
farmIds: string;
language?: string;
};

export type GetFertilizersParams = {
farmIds: string;
language?: string;
/**
 * Filter by fertilizer name
 */
search?: string;
/**
 * Set catalogue type
 */
catalogue?: CatalogueType;
/**
 * Include hidden fertilizers
 */
'include-hidden'?: boolean;
/**
 * Filter by fertilizer external id
 */
'external-ids'?: string[];
/**
 * Filter by fertilizer organic flag
 */
organic?: boolean;
/**
 * Filter by eAgri fertilizer nitrogen category type
 */
'eagri-nitrogen-category'?: EagriFertilizerNitrogenCategory[];
/**
 * Fertilizer validity end
 */
'valid-on'?: string;
/**
 * If true, returns only favorite fertilizers
 */
'favorite-only'?: boolean;
pageOffset?: number;
pageSize?: number;
sortProperty?: string;
};

export type PostActiveSubstanceParams = {
farmIds: string;
};

export type GetActiveSubstancesParams = {
farmIds: string;
search?: string;
};

export type CreateOrganismsParams = {
farmIds: string;
};

export type GetOrganismsParams = {
farmIds: string;
search?: string;
'is-used'?: boolean;
'valid-only'?: boolean;
};

export type PostPlantProtectionRegistrationApplicationParams = {
farmIds: string;
};

export type CopyPlantProtectionParams = {
farmIds: string;
'registration-ids': number[];
};

export type PostPlantProtectionParams = {
farmIds: string;
};

export type PostSeedParams = {
farmIds: string;
language?: string;
};

export type GetSeedsParams = {
farmIds: string;
/**
 * Catalog localization language
 */
language?: string;
pageOffset?: number;
pageSize?: number;
sortProperty?: string;
/**
 * Filter by crop name
 */
search?: string;
/**
 * Set catalogue type
 */
catalogue?: CatalogueType;
/**
 * Include hidden seeds
 */
'include-hidden'?: boolean;
/**
 * Filter by crop external id
 */
'external-ids'?: string[];
/**
 * Seed validity end
 */
'valid-on'?: string;
/**
 * If true, returns only favorite fertilizers
 */
'favorite-only'?: boolean;
};

export type RequestAvailableUpdatesParams = {
farmIds: string;
language?: string;
};

export type GetAvailableUpdatesParams = {
farmIds: string;
};

export type CreateParams = {
'dry-mode'?: boolean;
farmIds: string;
};

export type GetParcelAreaAfterSubtractionParams = {
farmIds: string;
};

export type GetParcelSubtractionGeometryParams = {
farmIds: string;
};

export type RecountSubtractionsForActionParams = {
farmIds: string;
};

export type CreateZoneParams = {
farmIds: string;
};

export type GetZonesParams = {
farmIds: string;
search?: string;
};

export type GetParcelsSubtractionParams = {
farmIds: string;
};

export type RegisterWithEagriParams = {
farmName: string;
language?: string;
srid?: number;
importMode?: EagriImportMode;
};

export type RegisterEmptyFarmParams = {
farmName: string;
countryCode: string;
language?: string;
};

export type ValidateShapefileBody = {
  lpisFile: Blob;
};

export type ValidateShapefileParams = {
countryCode: string;
};

export type RegisterWithShapeFileBody = {
  lpisFile: Blob;
};

export type RegisterWithShapeFileParams = {
farmName: string;
countryCode: string;
language?: string;
};

export type GetFertilizerUserReportBodyParams = {
farmIds: string;
};

export type SendFertilizerUserReportToEagri403 = MissingData | InvalidDateRange;

export type SendFertilizerUserReportToEagriParams = {
farmIds: string;
};

export type GetEagriPorUseReportBodyParams = {
farmIds: string;
toDate: string;
};

export type SendPorUserReportToEagri403 = ValidationError | ReportIsAlreadyRunning | EagriReportError;

export type SendPorUserReportToEagriParams = {
farmIds: string;
toDate: string;
};

export type CreateMissingSeasonsParams = {
farmIds: string;
startYear: number;
size: number;
};

export type GetSeasonsParams = {
farmIds: string;
startYear: number;
size: number;
};

export type CreateDraftActionParams = {
farmIds: string;
};

export type GetActionInfoParams = {
farmIds: string;
language: LocalLanguage;
};

export type GetParcelIntersectionsParams = {
farmIds: string;
language?: string;
'valid-from'?: string;
'valid-to'?: string;
};

export type GetPlantProtectionsInternalParams = {
farmIds: string;
};

export type CreateEPHActionParams = {
farmIds: string;
};

export type UpdateEPHActionParams = {
farmIds: string;
};

export type CreateHarvestActionParams = {
farmIds: string;
};

export type UpdateHarvestActionParams = {
farmIds: string;
};

export type CreateMowingActionParams = {
farmIds: string;
};

export type UpdateMowingActionParams = {
farmIds: string;
};

export type CreateOtherActionParams = {
farmIds: string;
};

export type UpdateOtherActionParams = {
farmIds: string;
};

export type CreateSowingActionParams = {
farmIds: string;
};

export type UpdateSowingActionParams = {
farmIds: string;
};

export type CreateNewSkeagisIntegrationParams = {
farmIds: string;
};

export type UpdateSkeagisStateParams = {
farmIds: string;
};

export type UpdateFarmFromShapefileBody = {
  shapefile: Blob;
};

export type UpdateFarmFromShapefileParams = {
farmIds: string;
};

export type OverwriteParcelGeometriesParams = {
farms: number;
'block-numbers': string[];
validity: string;
};

export type RecomputeFarmGeometriesParams = {
farms: number[];
};

export type UpdateSkeagisFarmParams = {
farms: number;
};

export type HideFertilizerParams = {
farmIds: string;
language?: string;
};

export type PutFertilizerParams = {
farmIds: string;
language?: string;
};

export type GetFertilizerParams = {
farmIds: string;
language?: string;
};

export type DeletePlantProtectionRegistrationApplicationParams = {
farmIds: string;
};

export type PutPlantProtectionRegistrationApplicationParams = {
farmIds: string;
};

export type PatchPlantProtectionParams = {
farmIds: string;
};

export type PutPlantProtectionParams = {
farmIds: string;
};

export type UpdateFarmParams = {
language?: string;
srid?: number;
'update-mode'?: EagriFullFarmUpdateMode;
'sync-catalogues'?: boolean;
'validity-date'?: string;
};

export type PutEagriAuthParams = {
language?: string;
};

export type GetEagriAuthParams = {
language?: string;
};

export type PutCropToParcelAndSeasonParams = {
farmIds: string;
};

export type GetParcelSowingPlan1Params = {
farmIds: string;
startYear: number;
size: number;
language?: string;
};

export type PutCropsToParcelAndSeasonParams = {
farmIds: string;
};

export type GetDetailedParcelWithSowingPlanParams = {
farmIds: string;
startYear: number;
size: number;
language: LocalLanguage;
/**
 * Filter by parcel name or block number.
 */
search?: string;
/**
 * Filter by parcel status.
 */
status?: ParcelStatus[];
/**
 * <p>Start of interval in which the parcel is considered valid.</p><p>If <b>valid-from</b> and/or <b>valid-to</b> filter is used, it will return parcels that are considered valid inside the given date range. If either <b>valid-from</b> or <b>valid-to</b> is null, the interval is considered open from respective side.<p>
 */
'valid-from'?: string;
/**
 * Filter by parcel land use.
 */
'land-use'?: LandUse[];
/**
 * Filter only parcels with given IDs.
 */
ids?: string[];
/**
 * Filter parcels by zone ID(s) of zones they are in. Use NONE to filter parcels with no zone.
 */
zones?: string[];
/**
 * Filter parcels by center ID(s) of centers they are in. Use NONE to filter parcels with no center.
 */
centers?: string[];
/**
 * Filter parcels by sown crop legislative codes.
 */
crops?: number[];
/**
 * Filters parcels by their nitrate vulnerable status.<ul><li>If <b>true</b>, filters only nitrate vulnerable parcels</li><li>If <b>false</b>, filters only nitrate invulnerable parcel</li><li>If <b>null</b>, no nitrate vulnerable filter is applied</li></ul>
 */
'nitrate-vulnerable'?: boolean;
/**
 * Filters parcels by their sown status.<ul><li>If <b>true</b>, filters only sown parcels</li><li>If <b>false</b>, filters only unsown parcel</li><li>If <b>null</b>, no sowning filter is applied</li></ul>
 */
sown?: boolean;
pageOffset?: number;
pageSize?: number;
sortProperty?: string;
};

export type UpdateCentersParams = {
farmIds: string;
};

export type ActionTypeGroupTo = typeof ActionTypeGroupTo[keyof typeof ActionTypeGroupTo];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionTypeGroupTo = {
  OTHER: 'OTHER',
} as const;

export interface TargetSeedApplicationActionsResponseTo {
  seedApplications: TargetSeedApplicationDetailTo[];
}

export interface PlantProtectionMinimalTo {
  activeSubstances: string[];
  id: string;
  name: string;
}

export interface FlatActionPlantProtectionTo {
  actionId: string;
  actionType: ActionType;
  date: string;
  parcelId: string;
  plantProtection: PlantProtectionMinimalTo;
}

export interface FlatActionPlantProtectionByMaterialTo {
  actions: FlatActionPlantProtectionTo[];
  plantProtectionId: string;
}

export interface TargetSeedApplicationDetailTo {
  accountableNitrogen: number;
  actionsByPlantProtection: FlatActionPlantProtectionByMaterialTo[];
  parcelId: string;
  seedApplication: SeedApplicationTo;
}

export type HarvestProduct = typeof HarvestProduct[keyof typeof HarvestProduct];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HarvestProduct = {
  NUMBER_1: '1',
  NUMBER_2: '2',
  NUMBER_3: '3',
  NUMBER_4: '4',
  NUMBER_5: '5',
  NUMBER_6: '6',
  NUMBER_7: '7',
  NUMBER_8: '8',
  NUMBER_9: '9',
  NUMBER_10: '10',
  NUMBER_11: '11',
  NUMBER_12: '12',
  NUMBER_13: '13',
  NUMBER_14: '14',
  NUMBER_15: '15',
  NUMBER_16: '16',
  NUMBER_17: '17',
  NUMBER_18: '18',
  NUMBER_19: '19',
  NUMBER_20: '20',
  NUMBER_21: '21',
  NUMBER_22: '22',
  NUMBER_23: '23',
  NUMBER_24: '24',
  NUMBER_25: '25',
  NUMBER_26: '26',
  NUMBER_27: '27',
  NUMBER_28: '28',
  NUMBER_29: '29',
  NUMBER_30: '30',
  NUMBER_31: '31',
  NUMBER_32: '32',
  NUMBER_33: '33',
  NUMBER_34: '34',
} as const;

export interface RevenueTo {
  actionId: string;
  amount: number;
  amountPerHa: number;
  isMainProduct: boolean;
  product?: HarvestProduct;
}

export type FertilizerTarget = typeof FertilizerTarget[keyof typeof FertilizerTarget];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FertilizerTarget = {
  CATCH_CROP: 'CATCH_CROP',
  MAIN_CROP: 'MAIN_CROP',
  STRAW: 'STRAW',
} as const;

export interface AverageFertilizationTo {
  cao: number;
  k2o: number;
  mgo: number;
  nitrogen: number;
  p2o5: number;
  sulfur: number;
}

export type ActionSowingDetailToAllOf = {
  cropUseType?: CropUseTypeTo;
  mainHarvestProduct?: HarvestProductTo;
  seedApplicationType: SeedApplicationType;
  seedExpense?: ActionExpenseSeedDetailTo;
};

export type ActionSowingDetailTo = ActionDetailTo & ActionSowingDetailToAllOf;

export type ActionRevenueDetailToAllOf = {
  revenues: RevenueTo[];
};

export type ActionRevenueDetailTo = ActionDetailTo & ActionRevenueDetailToAllOf;

export interface ActionParcelDetailTo {
  actionArea: number;
  id: string;
  parcel: ParcelTo;
  restrictedArea: number;
  restrictions: SubtractionResponse[];
}

export type ActionOtherDetailTo = ActionDetailTo;

export interface ActionExpenseSeedDetailTo {
  actionExpense?: ActionExpenseTo;
  actionMaterial: SeedMat;
  material: CropSeedTo;
  materialId: string;
}

export interface ActionExpensePlantProtectionDetailTo {
  actionExpense: ActionExpenseTo;
  actionMaterial: EPHMat;
  material: InternalMaterialTo;
  materialId: string;
  parcelPlantProtectionEffectiveness: ParcelPlantProtectionEffectivenessTo[];
  targetOrganisms: OrganismTo[];
}

export interface ActionExpenseFertilizerDetailTo {
  actionExpense: ActionExpenseTo;
  actionMaterial: EPHMat;
  fertilization: AverageFertilizationTo;
  material: FertilizerTo;
  materialId: string;
}

export type ActionEPHDetailToAllOf = {
  fertilizationTarget?: FertilizerTarget;
  fertilizers: ActionExpenseFertilizerDetailTo[];
  isStrawDecay?: boolean;
  plantProtections: ActionExpensePlantProtectionDetailTo[];
  targetCrop?: CropTo;
};

export interface ActionDetailTo {
  actionType: ActionType;
  date: string;
  id: string;
  isDraft: boolean;
  isEagriFromCoreParcels: boolean;
  note?: string;
  parcels: ActionParcelDetailTo[];
  source: ActionSource;
}

export type ActionEPHDetailTo = ActionDetailTo & ActionEPHDetailToAllOf;

export interface ActionParcelMinimalTo {
  actionArea: number;
  actionParcelId: string;
  blockNumber?: string;
  id: string;
  isNitrateVulnerable: boolean;
  landUse: LandUse;
  landUseLocalised?: string;
  name?: string;
  parcelArea: number;
  region?: string;
  restrictedArea: number;
  seedApplicationId: string;
}

export interface ActionExpenseOverviewTo {
  amount?: number;
  cao?: number;
  dosePerHa?: number;
  k2o?: number;
  materialId: string;
  materialLegislativeCode?: number;
  materialName: string;
  materialType: MaterialType;
  mgo?: number;
  nitrogen?: number;
  p2o5?: number;
  s?: number;
  unit?: string;
}

export interface ActionOverviewTo {
  actionType: ActionTypeTo;
  crop?: CropTo;
  date: string;
  expenses: ActionExpenseOverviewTo[];
  id: string;
  isDraft: boolean;
  isStrawDecay?: boolean;
  parcels: ActionParcelMinimalTo[];
  seedApplicationNitrogen?: number;
  seedApplicationType: SeedApplicationType;
  source: ActionSource;
}

export interface EagriServiceSyncStatusTo {
  lastData: string;
  lastRun: string;
  lastSuccess: string;
  service: string;
}

export interface ZemParcelyType {
  historie?: HistorieType[];
  idparcela?: number;
  idparcelapredek?: number;
  nazev: string;
  odpocplochy?: OdpocPlochyType[];
  parcelado?: string;
  parcelaod: string;
  parcelaverze?: ParcelaVerzeType[];
  plodina: PlodinaType[];
  plodinyrozsirene: PlodinyRozsireneType[];
  poradi?: number;
}

export interface ZakladniType {
  hodnotaint?: number;
  hodnotanum?: number;
  hodnotastr?: string;
  kod: string;
  platnostdo?: string;
  platnostod: string;
}

export type VyslednaErozeType = typeof VyslednaErozeType[keyof typeof VyslednaErozeType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VyslednaErozeType = {
  SEO: 'SEO',
  MEO: 'MEO',
  NEO: 'NEO',
} as const;

export type VyslednaEroze24Type = typeof VyslednaEroze24Type[keyof typeof VyslednaEroze24Type];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VyslednaEroze24Type = {
  SEO: 'SEO',
  MEO_VR: 'MEO_VR',
  MEO_NR: 'MEO_NR',
  NEO: 'NEO',
} as const;

export interface VynosHladinaType {
  platnostdo?: string;
  platnostod: string;
  vymprekryv: number;
  vynoshladinakod?: number;
}

export interface UzivatelType {
  ic?: string;
  iduzivatele?: number;
  jmeno?: string;
  obchodnijmeno?: string;
  pravniforma?: number;
  prijmeni?: string;
}

export interface UpobType {
  platnostdo?: string;
  platnostod: string;
  vymprekryv: number;
}

export interface UdajeNsType {
  hodnotaint?: number;
  hodnotanum?: number;
  hodnotastr?: string;
  kod: string;
  platnostdo?: string;
  platnostod: string;
}

export interface UdajeErozeType {
  hodnotaint?: number;
  hodnotanum?: number;
  hodnotastr?: string;
  kod: string;
  platnostdo?: string;
  platnostod: string;
}

export type TypEroznihoPozemkuType = typeof TypEroznihoPozemkuType[keyof typeof TypEroznihoPozemkuType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TypEroznihoPozemkuType = {
  EEP: 'EEP',
  VEP: 'VEP',
} as const;

export interface SeznamOtpType {
  datumsouvislyotp?: string;
  datumzahajeniotp: string;
  obnovatpid?: number;
  stav: number;
}

export interface SeznamEvpType {
  ctverec: string;
  druhid?: number;
  druhnazev: string;
  evpid?: number;
  geometrieevp: string;
  platnostdo?: string;
  platnostod: string;
  vymera: number;
  vymeraprekryv: number;
  zkod: string;
}

export interface PtopatreniType {
  kod: string;
}

export interface PrisluzdpbType {
  ctverec: string;
  fbid?: number;
  platnostdo?: string;
  platnostod: string;
  vymeradpb: number;
  zkod: string;
}

export interface PrekryvKatuzeType {
  dlecentroid?: number;
  kukod?: number;
  kunazev: string;
  platnostdo?: string;
  platnostod: string;
  vymprekryv: number;
}

export interface PrehlidkaType {
  datprovedeni: string;
  poradi: number;
  stav: number;
  vysledek: string;
}

export interface PlodinyRozsireneType {
  kodplodiny?: number;
  kodpot?: number;
  kodproduktu?: number;
  kodusp?: number;
  meziplodina?: boolean;
  pestovaniid?: number;
  platnostdo?: string;
  platnostod: string;
  pot?: string;
  poznamka?: string;
}

export interface PlodinaType {
  gps?: boolean;
  kodplodiny?: number;
  meziplodina?: boolean;
  pestovaniid?: number;
  platnostdo?: string;
  platnostod: string;
}

export interface OpvzType {
  kategorieopvz?: number;
  nazev?: string;
  overeno?: boolean;
  platnostdo?: string;
  platnostod: string;
  typ?: string;
  urlrozhodnuti?: string;
  vymprekryv: number;
}

export interface OpvType {
  geometrie: string;
  kodzakres: string;
}

export interface OpatreniNsType {
  hodnota: string;
  kodtyp: string;
  platnostdo?: string;
  platnostod: string;
}

export interface OpatreniMeoType {
  kodopatreni: KodOpatreniType;
  osevdo: string;
  osevod: string;
}

export interface OpatreniErozeType {
  cfaktordo?: string;
  cfaktorod?: string;
  erozekateg?: number;
  osevdo: string;
  osevod: string;
  vymprekryv: number;
}

export interface OdpocPlochyType {
  kodop?: number;
  platnostdo?: string;
  platnostod: string;
  sirka?: number;
  vymera: number;
}

export interface NeprodPlochyType {
  ctverecnp: string;
  id?: number;
  platnostdo?: string;
  platnostod: string;
  typnp: string;
  vymnp: number;
  zkodnp: string;
}

export interface MzpType {
  mzpkod: string;
  platnostdo?: string;
  platnostod: string;
  vymprekryv: number;
  vymprekryv4DM?: number;
}

export interface MnozPorostyType {
  cisrozhodnuti?: string;
  ciszadosti?: number;
  datpodani: string;
  druhkod?: number;
  druhnazev: string;
  generace: string;
  hodnoceni?: string;
  kategorie: string;
  kvalitavazby: string;
  odrudakod?: number;
  odrudanazev: string;
  prehlidka?: PrehlidkaType[];
  rok: number;
  vymerazadost: number;
  zadatel: string;
  zadatelico: string;
  zrusenazadost: string;
}

export interface LfaType {
  lfakod: string;
  platnostdo?: string;
  platnostod: string;
  vymprekryv: number;
  vymprekryv4DM?: number;
}

export type KodOpatreniType = typeof KodOpatreniType[keyof typeof KodOpatreniType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KodOpatreniType = {
  P_1: 'P_1',
  P_2: 'P_2',
  P_3: 'P_3',
  Z_0: 'Z_0',
  Z_1: 'Z_1',
  Z_2: 'Z_2',
  Z_3: 'Z_3',
  S_0: 'S_0',
  S_1: 'S_1',
  S_2: 'S_2',
  S_3: 'S_3',
  V_0: 'V_0',
  V_1: 'V_1',
  V_2: 'V_2',
  V_3: 'V_3',
  V_4: 'V_4',
  K: 'K',
  R: 'R',
  LOS: 'LOS',
  ST: 'ST',
  PK: 'PK',
} as const;

export interface HonitbaType {
  kod: string;
  nazev: string;
  platnostdo?: string;
  platnostod: string;
  prislusnostorp: string;
  vymprekryv: number;
}

export interface HistorieType {
  parcelaid?: number;
  parcelavymy: number;
  platnostdo?: string;
  platnostod: string;
  prekryv: number;
  vlastnizakre?: boolean;
}

export interface GmoType {
  plodinaid?: number;
  plodinanazev: string;
  vymera: number;
}

export interface GeoinfoType {
  hodnota: number;
  kod: string;
  platnostdo?: string;
  platnostod: string;
}

export interface EroznipozemekType {
  datumvytvoreni: string;
  geoinfo?: GeoinfoType[];
  id?: number;
  maxsouvseo?: number;
  maxsouvseomeo?: number;
  osevdo?: string;
  osevod?: string;
  poznamka?: string;
  prislusdpb?: PrisluzdpbType;
  ptopatreni?: PtopatreniType[];
  seocelkem?: number;
  seomeocelkem?: number;
  typ: TypEroznihoPozemkuType;
  vyslednaeroze?: VyslednaErozeType;
  vytvorilkdo: string;
}

export interface Eroze3GType {
  maxsouvseo: number;
  maxsouvseomeovr: number;
  maxsouvseomeovrmeonr: number;
  osevdo: string;
  osevod: string;
  ptopatreni?: PtopatreniType[];
  seocelkem: number;
  seomeovrcelkem: number;
  seomeovrmeonrcelkem: number;
  vyslednaeroze: VyslednaEroze24Type;
}

export interface Eroze2GType {
  maxsouvseo: number;
  maxsouvseomeo: number;
  osevdo: string;
  osevod: string;
  ptopatreni?: PtopatreniType[];
  seocelkem: number;
  seomeocelkem: number;
  vyslednaeroze: VyslednaErozeType;
}

export interface ParcelaVerzeType {
  aplpasmo?: AplPasmoType[];
  eroze2G?: Eroze2GType[];
  eroze3G?: Eroze3GType[];
  geometrie?: string;
  idparcelaverze?: number;
  multipolygon?: boolean;
  neprodplochy?: NeprodPlochyType[];
  opatrenins?: OpatreniNsType[];
  parcelaverzedo?: string;
  parcelaverzeod: string;
  vymera: number;
  zakladni?: ZakladniType[];
}

export interface EnviroType {
  kodlouky: string;
  kvalita: string;
  platnostdo?: string;
  platnostod: string;
  terminsec?: string;
  vymprekryv: number;
  zpusobpastvy?: string;
}

export interface EfasType {
  efaskod: string;
  platnostdo?: string;
  platnostod: string;
  vymefas: number;
  vymefasprep: number;
}

export interface ChybaType {
  kodchyby: string;
  popis: string;
}

export interface ChybyType {
  chyba: ChybaType[];
}

export interface BpejType {
  bpejkod: string;
  platnostdo?: string;
  platnostod: string;
  vymprekryv: number;
}

export interface AzzpType {
  kod?: number;
  souradnicex: string;
  souradnicey: string;
}

export interface AplPasmoType {
  aplpaskod?: number;
  platnostdo?: string;
  platnostod: string;
  vymprekryv: number;
}

export interface AekoUdajeType {
  aekokod: string;
  hodnotaint?: number;
  hodnotanum?: number;
  hodnotastr?: string;
  platnostdo?: string;
  platnostod: string;
}

export interface DpbType {
  aekoudaje?: AekoUdajeType[];
  aplpasmo?: AplPasmoType[];
  azzp?: AzzpType[];
  bpej?: BpejType[];
  chyba?: string;
  chyby?: ChybyType;
  ctverec: string;
  efas?: EfasType[];
  enviro?: EnviroType[];
  eroze2G?: Eroze2GType[];
  eroze3G?: Eroze3GType[];
  eroznipozemek?: EroznipozemekType[];
  geometriedpb?: string;
  gmo?: GmoType[];
  honitba?: HonitbaType[];
  iddpb?: number;
  kultura: string;
  kulturaid?: number;
  kulturanazev: string;
  kulturaod?: string;
  lfa?: LfaType[];
  mnozitelskeporosty?: MnozPorostyType[];
  mzp?: MzpType[];
  neprodplochy?: NeprodPlochyType[];
  opatrenieroze?: OpatreniErozeType[];
  opatrenimeo?: OpatreniMeoType[];
  opatrenins?: OpatreniNsType[];
  opv?: OpvType[];
  opvz?: OpvzType[];
  platnostdo?: string;
  platnostod: string;
  prekryvkatuze?: PrekryvKatuzeType[];
  seznamevp?: SeznamEvpType[];
  seznamotp?: SeznamOtpType[];
  stav: string;
  stavid?: number;
  ucinnostdlezakona?: string;
  udajeeroze?: UdajeErozeType[];
  udajens?: UdajeNsType[];
  upob?: UpobType[];
  uzivatel: UzivatelType;
  vymera: number;
  vymeraopv: number;
  vynoshladina?: VynosHladinaType[];
  zakladni?: ZakladniType[];
  zemparcely?: ZemParcelyType[];
  zkod: string;
}

export interface EagriDpbPair {
  detail?: DpbType;
  subsidyDpb: DpbType;
}

export interface AdminFarmUserTo {
  userId: string;
}

export interface CropUseTypeTo {
  cropUseType: EagriCropUseType;
  description: string;
  externalId: number;
}

export type EnumEntriesEagriFertilizerNitrogenCategory = EagriFertilizerNitrogenCategory[];

export interface FertilizerSuggestionTo {
  catalogue: CatalogueTo;
  id: string;
  isFavorite: boolean;
  isHidden: boolean;
  name: string;
  preferredUnitType: UnitType;
  producerTradeName?: string;
  validTo?: string;
}

export interface FertilizerMineralIntakeResponse {
  accountableNitrogenIntake: number;
  k2oIntake: number;
  nitrogenIntake: number;
  p2o5Intake: number;
}

export interface EagriFertilizerPropertiesTo {
  evidenceNumber?: string;
  isValid: boolean;
  nitrogenCategory: EagriFertilizerNitrogenCategoryTo;
  validFrom?: string;
  validTo?: string;
}

export interface PlantProtectionOverviewTo {
  activeSubstances: string[];
  catalogue: CatalogueTo;
  crops: string[];
  externalId?: string;
  id: string;
  isFavorite: boolean;
  name: string;
  organisms: string[];
  validUntil?: string;
}

export interface PlantProtectionSuggestionTo {
  catalogue: CatalogueTo;
  id: string;
  isFavorite: boolean;
  name: string;
  validUntil?: string;
}

export interface RestPlantProtectionSuggestionFilter {
  crop?: string[];
  'crop-id'?: string[];
  search?: string;
  usable?: string;
  'usable-until'?: string;
}

export interface PlantProtectionApplicationTo {
  allowedApplications?: number;
  buffers: PlantProtectionRegistrationApplicationBufferTo[];
  crop?: CropTo;
  cropGroup?: CropGroupTo;
  id: string;
  maxBbch?: number;
  maxDose?: number;
  maxDoseWater?: number;
  maxInterval?: number;
  minBbch?: number;
  minDose?: number;
  minDoseWater?: number;
  minInterval?: number;
  organism?: OrganismTo;
  protectionPeriod?: number;
  unit?: string;
  unitWater?: string;
  validUntil?: string;
}

export interface PlantProtectionDetailRegistrationApplicationTo {
  allowedApplications?: number;
  buffers: PlantProtectionRegistrationApplicationBufferTo[];
  crop?: CropTo;
  cropGroup?: CropGroupTo;
  doseComment?: string;
  doseText?: string;
  evidenceNumber?: string;
  id: string;
  isAerialApplication?: boolean;
  isSeedTreatment?: boolean;
  legalEnd?: string;
  legalStart?: string;
  marketEnd?: string;
  maxBbch?: number;
  maxDose?: number;
  maxDoseWater?: number;
  maxInterval?: number;
  minBbch?: number;
  minDose?: number;
  minDoseWater?: number;
  minInterval?: number;
  notes?: string;
  organism?: OrganismTo;
  protectionPeriod?: number;
  registrationExternalId?: string;
  registrationHolder?: string;
  registrationNumber?: string;
  unit?: string;
  unitWater?: string;
  useEnd?: string;
  validUntil?: string;
}

export interface CropSeedSuggestionTo {
  catalogue: CatalogueTo;
  cropExternalId: number;
  id: string;
  isFavorite: boolean;
  isHidden: boolean;
  name: string;
  varietyName?: string;
}

export interface CountryTo {
  code: string;
  fullSupport: boolean;
  name: string;
}

export interface FarmCustomerTo {
  countryCode: string;
  id: string;
  schemaName: string;
}

export interface DeprecatedFarmTo {
  boundingBox?: GeoJsonPolygon;
  code: string;
  createdBy?: string;
  createdOn?: string;
  customer: FarmCustomerTo;
  id: string;
  name?: string;
  timeZone: string;
}

export interface InternalFarm {
  code: string;
  countryCode: string;
  id: string;
  name?: string;
}

export type FarmUpdateChangeType = typeof FarmUpdateChangeType[keyof typeof FarmUpdateChangeType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FarmUpdateChangeType = {
  NEW_PARCEL: 'NEW_PARCEL',
  NEW_BLOCK: 'NEW_BLOCK',
  REPLACED_PARCEL: 'REPLACED_PARCEL',
  REPLACED_BLOCK: 'REPLACED_BLOCK',
  NEW_BLOCK_RESTRICTION_CODES: 'NEW_BLOCK_RESTRICTION_CODES',
  REMOVED_BLOCK_RESTRICTION_CODES: 'REMOVED_BLOCK_RESTRICTION_CODES',
  CHANGED_PARCEL_NAME: 'CHANGED_PARCEL_NAME',
  CHANGED_PARCEL_CROPS: 'CHANGED_PARCEL_CROPS',
  NEW_PARCEL_RESTRICTION_CODES: 'NEW_PARCEL_RESTRICTION_CODES',
  REMOVED_PARCEL_RESTRICTION_CODES: 'REMOVED_PARCEL_RESTRICTION_CODES',
  RETIRED_BLOCK: 'RETIRED_BLOCK',
} as const;

export type FarmUpdateChangeRequestStatus = typeof FarmUpdateChangeRequestStatus[keyof typeof FarmUpdateChangeRequestStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FarmUpdateChangeRequestStatus = {
  IN_PROGRESS: 'IN_PROGRESS',
  AVAILABLE: 'AVAILABLE',
} as const;

export interface FarmUpdateChange {
  blockNumber: string;
  changeDate?: string;
  type: FarmUpdateChangeType;
}

export interface FarmUpdateChangesResponse {
  changes?: FarmUpdateChange[];
  status: FarmUpdateChangeRequestStatus;
  validityDate: string;
}

export interface FarmSettingsTo {
  eagriSettingsEnabled: boolean;
}

export type FarmUpdateState = typeof FarmUpdateState[keyof typeof FarmUpdateState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FarmUpdateState = {
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
} as const;

export type FarmUpdateSource = typeof FarmUpdateSource[keyof typeof FarmUpdateSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FarmUpdateSource = {
  EMPTY_FARM: 'EMPTY_FARM',
  EAGRI: 'EAGRI',
  SKEAGIS: 'SKEAGIS',
  SHP: 'SHP',
} as const;

export interface FarmUpdateNewParcels {
  block?: string;
  parcelId?: string;
}

export interface FarmUpdateErrorFlatResponse {
  block?: string;
  changeGeometry?: GeoJson;
  conflictingGeometry?: GeoJson;
  geometryToRepair?: GeoJson;
  parcelId?: string;
}

export interface FarmUpdateFlatResponse {
  ambiguousParcels?: string[];
  code?: string;
  conflictingBlocks?: string[];
  errorMessage?: string;
  farmUpdateErrorFlatResponse: FarmUpdateErrorFlatResponse[];
  farmUpdateId: string;
  lastSuccessUpdateDate?: string;
  newAutomaticParcels?: FarmUpdateNewParcels[];
  shortCode?: string;
  source: FarmUpdateSource;
  startedAt: string;
  state: FarmUpdateState;
  updatedAt?: string;
}

export interface ParcelCurrentSeedApplicationColorTo {
  color: ColorTo;
  parcelId: string;
  textColor: ColorTo;
}

export interface LandUseResponse {
  key: LandUse;
  name: string;
}

export interface ParcelStatisticsTo {
  count: number;
  totalArea: number;
}

export interface CropStatisticTo {
  color: ColorTo;
  count: number;
  cropId: string;
  externalId: number;
  name: string;
  textColor: ColorTo;
  totalArea: number;
}

export interface FarmStatisticsTo {
  crops: CropStatisticTo[];
  parcels: ParcelStatisticsTo;
}

/**
 * Parcel suggestions list item
 */
export interface ParcelSuggestionTo {
  blockNumber: string;
  id: string;
  localName: string;
  status: ParcelStatus;
  validTo?: string;
}

export type NitrateRestrictionSource = typeof NitrateRestrictionSource[keyof typeof NitrateRestrictionSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NitrateRestrictionSource = {
  EAGRI: 'EAGRI',
  LAND_USE: 'LAND_USE',
  YIELD_GRADE: 'YIELD_GRADE',
} as const;

export interface EagriSubsidyTo {
  blockNr: string;
  code: string;
  cropId?: number;
  declaredArea: number;
  description: string;
  from: string;
  square: string;
  subsidyTitleId: number;
  subsidyTypeId: number;
  to?: string;
}

export interface ParcelEagriInfoTo {
  aeko: EagriSubsidyTo[];
  aekoYear?: number;
  nitrate: EagriNitrateRestrictionByYearTo[];
}

export interface EagriNitrateRestrictionWithDescriptionTo {
  code: string;
  description: string;
  source: NitrateRestrictionSource;
  validFrom?: string;
  validTo?: string;
}

export interface EagriNitrateRestrictionByYearTo {
  isOldNitrate: boolean;
  nitrateRestrictions: EagriNitrateRestrictionWithDescriptionTo[];
  year?: number;
}

export interface ParcelPredecessorTo {
  blockNumber?: string;
  id: string;
  lastCrop?: CropTo;
  localName: string;
}

export interface FertilizationTo {
  /** Kilos of nitrogen per ha that will be absorbed by soil. Used for nitrate restrictions. */
  accountableNitrogen: number;
  cao: number;
  /** Kilos of k2o per ha */
  k2o: number;
  mgo: number;
  /** Kilos of nitrogen per ha */
  nitrogen: number;
  /** Kilos of p2o5 per ha */
  p2o5: number;
  sulfur: number;
}

export interface ParcelSeedApplicationTo {
  cropUseType?: EagriCropUseType;
  endDate?: string;
  fertilization: FertilizationTo;
  id: string;
  isHarvestable: boolean;
  parcelId: string;
  primaryRevenueHa?: number;
  secondaryRevenueHa?: number;
  seed: CropSeedTo;
  seedingActionId: string;
  sowingMainHarvestProduct?: HarvestProductTo;
  sownArea: number;
  startDate: string;
  terminalActionId?: string;
  type: SeedApplicationType;
}

/**
 * Parcel application zone from LPIS
 */
export type ApplicationZone = typeof ApplicationZone[keyof typeof ApplicationZone];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApplicationZone = {
  I: 'I',
  II: 'II',
  IIIa: 'IIIa',
  IIIb: 'IIIb',
} as const;

export interface EagriParcelAttributesTo {
  /**
   * Average parcel altitude
   * @minimum 0
   */
  altitude?: number;
  applicationZone?: ApplicationZone;
  landUse: LandUse;
  /** Designated parcel land use localized name */
  landUseName: string;
  lpisBlockArea: number;
  /**
   * Average parcel slope
   * @minimum 0
   */
  slope?: number;
  /** Bonitiated soil ecological unit = BPEJ */
  soilEcologicalUnit?: string;
  userYieldGrade?: YieldGrade;
  /**
   * Minimal parcel water distance
   * @minimum 0
   */
  waterDistance?: number;
  yieldGrade?: YieldGrade;
}

export type ParcelSource = typeof ParcelSource[keyof typeof ParcelSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ParcelSource = {
  MANUAL: 'MANUAL',
  SHP: 'SHP',
  AGROEVIDENCE_EAGRI: 'AGROEVIDENCE_EAGRI',
  AGROEVIDENCE_EAGRI_AUTOMATIC: 'AGROEVIDENCE_EAGRI_AUTOMATIC',
  SKEAGIS: 'SKEAGIS',
  CORE: 'CORE',
} as const;

export interface CenterTo {
  id: string;
  key: string;
  name: string;
}

/**
 * Parcel detail
 */
export interface ParcelDetailTo {
  area: number;
  blockNumber: string;
  center?: CenterTo;
  eagri: EagriParcelAttributesTo;
  eagriRestrictions: string[];
  geometry: GeoJsonPolygon;
  id: string;
  localName: string;
  nitrateVulnerable: boolean;
  notes?: string;
  parcelSource: ParcelSource;
  seedApplication?: SeedApplicationTo;
  status: ParcelStatus;
  validFrom: string;
  validTo?: string;
  zone?: ZoneTo;
  zones?: ZoneTo[];
}

/**
 * Parcel list item
 */
export interface ParcelTo {
  area: number;
  blockNumber: string;
  center?: CenterTo;
  id: string;
  landUseName: string;
  localName: string;
  lpisBlockArea: number;
  nitrateVulnerable: boolean;
  seedApplication?: SeedApplicationTo;
  source: ParcelSource;
  status: ParcelStatus;
  validFrom: string;
  validTo?: string;
  zone?: ZoneTo;
  zones?: ZoneTo[];
}

export interface TimeStamp {
  type?: string;
  value?: string;
}

export interface SRVid {
  serverID?: string;
}

export interface ResponseHeader {
  requestID: string;
}

export interface ResponseContent {
  response?: TResponse;
}

export interface Response {
  responseContent: ResponseContent;
  responseHeader?: ResponseHeader;
  sid?: string;
  srvid?: SRVid;
  timeStamp?: TimeStamp;
  vokoid?: string;
}

export interface Chyba {
  kod?: number;
  popis: string;
  zavaznost: string;
}

export interface TChyby {
  chyba: Chyba[];
}

export interface TResponse {
  aplikace?: Applications;
  chyby?: TChyby;
  datPrijeti: string;
  idPodani: string;
  obdobiDo: string;
  obdobiOd: string;
  osevy?: Osevy;
  rozsah?: string;
  stavPodani: string;
  stredisko?: string;
  typ: string;
}

export interface WriteListener { [key: string]: unknown }

export interface ServletOutputStream {
  ready?: boolean;
  writeListener?: WriteListener;
}

export type HttpServletResponseWriter = { [key: string]: unknown };

export type HttpServletResponseTrailerFields = { [key: string]: unknown };

export type HttpServletResponseLocale = {
  country?: string;
  displayCountry?: string;
  displayLanguage?: string;
  displayName?: string;
  displayScript?: string;
  displayVariant?: string;
  extensionKeys?: string[];
  iso3Country?: string;
  iso3Language?: string;
  language?: string;
  script?: string;
  unicodeLocaleAttributes?: string[];
  unicodeLocaleKeys?: string[];
  variant?: string;
};

export interface HttpServletResponse {
  bufferSize?: number;
  characterEncoding?: string;
  committed?: boolean;
  contentLength?: number;
  contentLengthLong?: string;
  contentType?: string;
  headerNames?: string[];
  locale?: HttpServletResponseLocale;
  outputStream?: ServletOutputStream;
  status?: number;
  trailerFields?: HttpServletResponseTrailerFields;
  writer?: HttpServletResponseWriter;
}

export type ReportErrorBody = PorUseReportMissingDataBody | FertilizerUseReportMissingDataBody;

export type EagriReportStatusType = typeof EagriReportStatusType[keyof typeof EagriReportStatusType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EagriReportStatusType = {
  OK: 'OK',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
} as const;

export interface ReportHistoryTo {
  eagriGuid?: string;
  eagriStatus?: EagriReportStatusType;
  finished?: string;
  id: string;
  jsonNote?: ReportErrorBody;
  note?: string;
  periodFrom: string;
  periodTo: string;
  started: string;
  success?: boolean;
  type: ReportType;
}

export type FileType = typeof FileType[keyof typeof FileType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileType = {
  PDF: 'PDF',
  XLSX: 'XLSX',
  CSV: 'CSV',
} as const;

export interface ZoneTo {
  id: string;
  name: string;
}

export interface SowingPlanSeasonCropTo {
  crop?: CropTo;
  season: SowingPlanSeasonTo;
}

export interface SeedApplicationTo {
  cropUseType?: EagriCropUseType;
  endDate?: string;
  id: string;
  isHarvestable: boolean;
  parcelId: string;
  seed: CropSeedTo;
  seedingActionId: string;
  sowingMainHarvestProduct?: HarvestProductTo;
  sownArea: number;
  startDate: string;
  terminalActionId?: string;
  type: SeedApplicationType;
}

export interface ParcelWithSowingPlansTo {
  area: number;
  blockNumber: string;
  id: string;
  localName: string;
  lpisBlockArea: number;
  seasons: SowingPlanSeasonCropTo[];
  seedApplication?: SeedApplicationTo;
  zones?: ZoneTo[];
}

export type LandUse = typeof LandUse[keyof typeof LandUse];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LandUse = {
  ARABLE: 'ARABLE',
  HOPFIELD: 'HOPFIELD',
  VINEYARD: 'VINEYARD',
  OTHER_PERMANENT: 'OTHER_PERMANENT',
  ORCHARD: 'ORCHARD',
  GRASS: 'GRASS',
  OTHER: 'OTHER',
  SET_ASIDE: 'SET_ASIDE',
  FODDER: 'FODDER',
  UNPRODUCTIVE: 'UNPRODUCTIVE',
  MULTIPLE_CROPS: 'MULTIPLE_CROPS',
  TRUFFLES: 'TRUFFLES',
  CONTAINERS: 'CONTAINERS',
  NURSERY: 'NURSERY',
  VEGETABLES: 'VEGETABLES',
  POND: 'POND',
  FAST_GROW: 'FAST_GROW',
  WOODED: 'WOODED',
} as const;

export interface SowingPlanOverviewParcelTo {
  area: number;
  crop?: CropTo;
  parcelId: string;
  seasonId: string;
}

export interface SowingPlanCropStatisticsOverview {
  area: number;
  crop?: CropTo;
  parcelsCount: number;
}

export interface SowingPlanSeasonStatisticsTo {
  parcels: SowingPlanOverviewParcelTo[];
  statistics: SowingPlanCropStatisticsOverview[];
}

export interface InternalCropTo {
  externalId: string;
  id: string;
  name: string;
  pfCode?: string;
}

export type CropCatalogueType = typeof CropCatalogueType[keyof typeof CropCatalogueType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CropCatalogueType = {
  EAGRI: 'EAGRI',
  GLOBAL: 'GLOBAL',
  PPA_SK: 'PPA_SK',
} as const;

export interface InternalFarmUserResponse {
  customerId: number;
  defaultFarmUser?: boolean;
  farmCode: string;
  userId: string;
}

export interface InternalFarmResponse {
  countryCode?: string;
  id: string;
  name: string;
  source: InternalFarmImportSource;
}

export interface InternalFertilizerMineralIntakeResponse {
  accountableNitrogen: number;
  cao: number;
  k2o: number;
  mgo: number;
  nitrogen: number;
  p2o5: number;
  sulfur: number;
}

export interface InternalSeedApplicationTo {
  dateEnd?: string;
  dateStart: string;
  harvestProduct?: HarvestProductTo;
  seedApplicationType: InternalSeedApplicationType;
  seedTo: CropSeedTo;
}

export interface InternalSeasonTo {
  cropId: string;
  cropLegislativeCode: number;
  cropName: string;
  startYear: number;
}

export interface PublicCropTo {
  id: string;
  name: string;
  variety?: string;
}

export interface PublicParcelTo {
  area: number;
  blockNumber: string;
  currentCrop?: PublicCropTo;
  geometry: GeoJsonPolygon;
  id: string;
  name: string;
}

export type ParcelStatus = typeof ParcelStatus[keyof typeof ParcelStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ParcelStatus = {
  OPEN: 'OPEN',
  RETIRED: 'RETIRED',
} as const;

export interface FertilizerPatchTo {
  isFavorite?: boolean;
  isHidden?: boolean;
}

export interface PlantProtectionPatchTo {
  isFavorite: boolean;
}

export interface CropSeedPatchTo {
  isFavorite?: boolean;
  isHidden?: boolean;
  varietyName?: string;
}

export interface ZoneAssignTo {
  zoneId?: string;
}

export type YieldGrade = typeof YieldGrade[keyof typeof YieldGrade];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const YieldGrade = {
  I: 'I',
  II: 'II',
  III: 'III',
} as const;

export interface CenterAssignTo {
  centerId?: string;
}

export interface ParcelUpdateTo {
  center?: CenterAssignTo;
  name?: string;
  notes?: string;
  userYieldGrade?: YieldGrade;
  zone?: ZoneAssignTo;
  zoneIds?: string[];
}

export type ViolationSeverity = typeof ViolationSeverity[keyof typeof ViolationSeverity];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ViolationSeverity = {
  ERROR: 'ERROR',
  WARNING: 'WARNING',
} as const;

export interface ViolationParcelCropTo {
  cropEndDate?: string;
  cropName: string;
  cropStartDate?: string;
  eagriId: number;
  isCatchCrop: boolean;
  /** ID of the parcel that violates the restriction. It may be historic parcel ID. */
  parcelId?: string;
  seasonStartYear?: number;
}

export interface ViolationParcelTo {
  crops?: ViolationParcelCropTo[];
  eagriCodes: string[];
  id: string;
  name: string;
}

export interface ViolationTo {
  code: string;
  message: string;
  parcel?: ViolationParcelTo;
  parcelId?: string;
  severity: ViolationSeverity;
  text: string;
}

export interface ValidationResponseTo {
  restrictions: ValidationResultTo[];
  valid: boolean;
}

export type RestrictionCode = typeof RestrictionCode[keyof typeof RestrictionCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RestrictionCode = {
  EagriCodeRestrictions: 'EagriCodeRestrictions',
  GrasslandsFertilizationRestriction: 'GrasslandsFertilizationRestriction',
  SlopeWaterFertilizationRestriction: 'SlopeWaterFertilizationRestriction',
  SlopeWaterSowingRestriction: 'SlopeWaterSowingRestriction',
} as const;

export type NitrateDirectiveVersion = typeof NitrateDirectiveVersion[keyof typeof NitrateDirectiveVersion];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NitrateDirectiveVersion = {
  NITRATE_DIRECTIVE_2015: 'NITRATE_DIRECTIVE_2015',
  NITRATE_DIRECTIVE_2016: 'NITRATE_DIRECTIVE_2016',
  NITRATE_DIRECTIVE_2020: 'NITRATE_DIRECTIVE_2020',
  NITRATE_DIRECTIVE_ALL: 'NITRATE_DIRECTIVE_ALL',
} as const;

export interface ValidationResultTo {
  code: RestrictionCode;
  description: string;
  version: NitrateDirectiveVersion;
  violated: boolean;
  violations: ViolationTo[];
}

export interface SplitActionResponse {
  originalActionId: string;
  splitActionId: string;
}

export interface SplitActionRequest {
  actionId: string;
  parcelsToSplit: string[];
}

export interface ParcelAccountableNitrogenTo {
  accountableNitrogen: number;
  parcelId: string;
}

export interface FertilizerExpenseRequestTo {
  dosePerHa: number;
  fertilizerId: string;
  unit: string;
}

export interface SeedApplicationFertilizationRequestTo {
  actionArea: number;
  fertilizerExpenses: FertilizerExpenseRequestTo[];
  isStrawDecay: boolean;
  parcelId: string;
  targetSeedAppSownArea: number;
}

export interface TargetSeedApplicationFertilizationRequestTo {
  parcelFertilizerExpenses: SeedApplicationFertilizationRequestTo[];
}

export interface FarmSkeagisCreateRequest {
  skeagisUserId: string;
  state: IntegrationState;
  validFrom: string;
}

export interface AdminEagriTestFarmCreateRequest {
  customerName: string;
  eagriLogin: string;
  eagriSzrid: string;
  eagriWsKey: string;
  farmName: string;
  importMode: EagriImportMode;
  language: string;
  srid: number;
  userId: string;
  validityDate: string;
}

export interface AdminActionMoveResult {
  nokFarms: string[];
  okFarms: string[];
}

export interface AdminParcelValidityMoveItem {
  newDate: string;
  parcelId: string;
}

export interface AdminParcelValidityMoveResult {
  actionMovesResult: ActionInheritanceResult[];
  changed: AdminParcelValidityMoveItem[];
  noop: string[];
}

export interface ActionInheritanceDto {
  actionId: string;
  actionType: ActionType;
  newParcelId: string;
  retiredParcelId: string;
}

export interface ActionInheritanceResult {
  movedActions: ActionInheritanceDto[];
  notMovedActionDuplicated: ActionInheritanceDto[];
  notMovedActionsLowRank: ActionInheritanceDto[];
  notMovedActionsOther: ActionInheritanceDto[];
}

export interface AdminNitrateVulnerableZoneTo {
  isNitrateVulnerable: boolean;
  lpisBlockId: string;
  parcelId: string;
  parcelName?: string;
}

export type AdminFarmManagementToResponse = { [key: string]: unknown };

export interface AdminFarmManagementTo {
  farmId: string;
  farmName?: string;
  response: AdminFarmManagementToResponse;
}

export interface ActiveSubstanceCreateTo {
  isIntegratedProductionOrchard?: boolean;
  isIntegratedProductionVegetable?: boolean;
  isIntegratedProductionVineyard?: boolean;
  name: string;
}

export interface OrganismTo {
  catalogue: CatalogueTo;
  externalId?: string;
  id: string;
  name: string;
}

export interface CreateOrganismRequestTo {
  name: string;
}

export interface CropSeedTo {
  catalogue: CatalogueTo;
  crop: CropTo;
  id: string;
  isFavorite: boolean;
  isHidden: boolean;
  name: string;
  varietyName?: string;
}

export interface CropSeedCreateTo {
  cropId: string;
  note?: string;
  varietyId?: string;
  varietyName?: string;
}

export interface CustomParcelCreateResult {
  area: number;
  geometry: GeoJsonPolygon;
  landUseId: number;
  localName: string;
  parcelId?: string;
}

export interface CustomParcelCreateRequest {
  geometry: GeoJsonPolygon;
  landUseId: number;
  localName: string;
  validFrom: string;
}

export interface ParcelAreaTo {
  area: number;
  parcelId: string;
}

export interface ParcelGeometryResponse {
  geometry: GeoJson;
  parcelId: string;
}

export interface ParcelRestrictionTo {
  parcelId: string;
  restrictions: RestrictionTo[];
}

export interface ParcelRestrictionRequestBody {
  parcelRestrictions: ParcelRestrictionTo[];
}

export interface SubtractionResponse {
  area: number;
  isMaterial: boolean;
  isUsed: boolean;
  protectionLevel?: number;
  type: RestrictionType;
  value?: number;
}

export interface ParcelSubtractionResponse {
  parcelId: string;
  subtractions: SubtractionResponse[];
}

export interface RecountSubtractionTo {
  isMaterial: boolean;
  isUsed: boolean;
  protectionLevel?: number;
  type: RestrictionType;
  value?: number;
}

/**
 * All parcels for action have to be sent. Even if there is no restriction for them.
 */
export interface ParcelRecountSubtractionTo {
  parcelId: string;
  restrictions: RecountSubtractionTo[];
}

export interface MaterialApplicationRestrictionTo {
  driftClass: DriftClass;
  plantProtectionId: string;
  targetCropId: string;
  targetOrganismIds: string[];
}

export interface RecountSubtractionRequest {
  materials: MaterialApplicationRestrictionTo[];
  /** All parcels for action have to be sent. Even if there is no restriction for them. */
  restrictions: ParcelRecountSubtractionTo[];
}

export interface ZoneDetailTo {
  id: string;
  name: string;
  parcelCount: number;
  totalArea: number;
}

export interface ZoneCreateTo {
  name: string;
}

export interface SubtractionTo {
  area: number;
  protectionLevel?: number;
  type: RestrictionType;
  value?: number;
}

export type EagriImportMode = typeof EagriImportMode[keyof typeof EagriImportMode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EagriImportMode = {
  BLOCKS: 'BLOCKS',
  BLOCKS_AND_PARCELS: 'BLOCKS_AND_PARCELS',
  ALL: 'ALL',
} as const;

export interface FarmTo {
  boundingBox?: GeoJsonPolygon;
  code: string;
  countryCode: string;
  createdBy?: string;
  createdOn?: string;
  id: string;
  latestUpdate?: string;
  name?: string;
  timeZone: string;
}

export interface Applcations {
  aplikace: Aplikace[];
}

export interface FertiliserUseBody {
  aplikace?: Applcations;
  obdobiDo: string;
  obdobiOd: string;
  osevy: Osevy;
  rozsah?: string;
  stredisko?: string;
  typ: string;
}

export interface EagriReportResponse {
  guid: string;
}

export type InvalidDateRangeDetailedMessage = { [key: string]: unknown };

export interface InvalidDateRange {
  detail: string;
  detailedMessage?: InvalidDateRangeDetailedMessage;
  error: string;
  status: number;
  trace?: string;
}

export interface FertilizerUseReportMissingDataBody {
  actionIdsMissingUsage: string[];
}

export interface MissingData {
  detail: string;
  detailedMessage: FertilizerUseReportMissingDataBody;
  error: string;
  status: number;
  trace?: string;
}

export type EagriFertilizerUseReportType = typeof EagriFertilizerUseReportType[keyof typeof EagriFertilizerUseReportType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EagriFertilizerUseReportType = {
  STATISTICS: 'STATISTICS',
  CHECK: 'CHECK',
} as const;

export interface EagriReportBody {
  from: string;
  to: string;
  type: EagriFertilizerUseReportType;
}

export interface Vymery {
  platnostDo?: string;
  platnostOd: string;
  vymera: number;
}

export interface PorUseBody {
  aplikace?: Applications;
  obdobiDo: string;
  obdobiOd: string;
  osevy: Osevy;
}

export interface Pestovani {
  hospRok?: number;
  idPestovani: string;
  idPlodina?: number;
  platnostDo?: string;
  platnostOd: string;
  typPlodiny?: string;
  ukonceniPestovani?: string;
  viceleta?: boolean;
  zahajeniPestovani: string;
}

export interface Osev {
  ctverec: string;
  idPozemek: string;
  nazevPozemek?: string;
  pestovani: Pestovani[];
  platnostDo?: string;
  platnostOd: string;
  vymery: Vymery[];
  zkod: string;
}

export interface Osevy {
  osev: Osev[];
}

export interface Aplikace {
  datAplikace: string;
  davka: number;
  idPestovani?: string;
  idPlodina?: number;
  idPor?: number;
  idSkodlivyOrganismus?: number;
  idSkodlivyOrganismusPPP?: number;
  mernaJednotkaObjekt: string;
  mernaJednotkaPor: string;
  nazevObjekt?: string;
  nazevPor?: string;
  nazevSkodlivyOrganismus?: string;
  rozsahAplikace: number;
  tankmix?: boolean;
  typ: string;
  ucinnostAplikace?: boolean;
}

export interface Applications {
  aplikace: Aplikace[];
}

export interface SeedApplicationMinimalTo {
  endActionId?: string;
  parcelExternalId?: string;
  parcelId: string;
  parcelName?: string;
  seedAppEnd?: string;
  seedAppStart: string;
  seedExternalId: string;
  seedId: string;
  seedName: string;
  startSeedAppId: string;
}

export type PlantProtectionPairingResultToAllItem = { [key: string]: unknown };

export interface FlatPlantProtectionMinimalTo {
  actionId: string;
  actionType: ActionType;
  date: string;
  materialName: string;
  parcelId: string;
  targetCropName: string;
}

export interface PairedPlantProtectionTo {
  plantProtection: FlatPlantProtectionMinimalTo;
  seedApplication: SeedApplicationMinimalTo;
}

export interface PlantProtectionPairingResultTo {
  all: PlantProtectionPairingResultToAllItem[];
  notPaired: FlatPlantProtectionMinimalTo[];
  paired: PairedPlantProtectionTo[];
  pairedOnFollowing: PairedPlantProtectionTo[];
  sendedSeedApplications: SeedApplicationMinimalTo[];
}

export type EagriReportErrorDetailedMessage = { [key: string]: unknown };

export interface EagriReportError {
  detail: string;
  detailedMessage?: EagriReportErrorDetailedMessage;
  error: string;
  status: number;
  trace?: string;
}

export type ReportType = typeof ReportType[keyof typeof ReportType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportType = {
  POR_USE: 'POR_USE',
  FERTILIZER_USE: 'FERTILIZER_USE',
} as const;

export type ReportIsAlreadyRunningDetailedMessage = { [key: string]: unknown };

export interface ReportIsAlreadyRunning {
  detail: string;
  detailedMessage?: ReportIsAlreadyRunningDetailedMessage;
  error: string;
  farmId?: string;
  status: number;
  trace?: string;
  type?: ReportType;
}

export interface PorValidationErrorMaterialResponse {
  dosePerHa: number;
  materialId: string;
  name: string;
  parcels?: ParcelNameResponse[];
  totalDose: number;
  unit: string;
}

export interface PorValidationErrorActionResponse {
  actionId: string;
  actionType: ActionTypeTo;
  date: string;
  materials: PorValidationErrorMaterialResponse[];
  targetCrop?: CropTo;
}

export interface PorUseReportMissingDataBody {
  actionIdsMissingTargetCrop: PorValidationErrorActionResponse[];
  actionsWithInvalidEffectiveness: PorValidationErrorActionResponse[];
  actionsWithInvalidOrganisms: PorValidationErrorActionResponse[];
  actionsWithInvalidUnit: PorValidationErrorActionResponse[];
}

export interface ValidationError {
  detail: string;
  detailedMessage: PorUseReportMissingDataBody;
  error: string;
  status: number;
  trace?: string;
}

export interface ParcelNameResponse {
  id: string;
  name: string;
}

export interface CropGroupTo {
  externalId: string;
  /** Crop group legislative id / external id */
  id: string;
  legislativeCode: string;
  name?: string;
}

export interface ColorTo {
  asDecimal: number;
  blue: number;
  green: number;
  red: number;
}

export interface CropTo {
  catalogueType: CatalogueType;
  color: ColorTo;
  externalId: number;
  group?: CropGroupTo;
  id: string;
  name: string;
  pfCode?: string;
  textColor: ColorTo;
  validFrom?: string;
  validTo?: string;
}

export interface ActionTypeTo {
  code: ActionType;
  name: string;
}

export interface SowingPlanSeasonTo {
  endYear: number;
  id: string;
  startYear: number;
}

export type InternalActionType = typeof InternalActionType[keyof typeof InternalActionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InternalActionType = {
  PLOW: 'PLOW',
  TILLAGE: 'TILLAGE',
  SOWING: 'SOWING',
  SOWING_PERENNIAL: 'SOWING_PERENNIAL',
  MOWING: 'MOWING',
  HARVEST: 'HARVEST',
  SPRAY: 'SPRAY',
  SPREADING_PEST: 'SPREADING_PEST',
  FERT: 'FERT',
  LIMING: 'LIMING',
  FERT_ORG: 'FERT_ORG',
  FERT_IND: 'FERT_IND',
  TANKMIX: 'TANKMIX',
  HOE: 'HOE',
  TRANSPORT: 'TRANSPORT',
  PREP: 'PREP',
  PASTURE: 'PASTURE',
  DISK: 'DISK',
  HARROW: 'HARROW',
  ROLL: 'ROLL',
  IRRIG: 'IRRIG',
  HILL: 'HILL',
  CULTIV: 'CULTIV',
  FOLIAR: 'FOLIAR',
  MULCHING: 'MULCHING',
  SUBSOILING: 'SUBSOILING',
  RAKING: 'RAKING',
  REVERSAL: 'REVERSAL',
  CRUSHING: 'CRUSHING',
  SKIDDING: 'SKIDDING',
  SUB_PLOWING: 'SUB_PLOWING',
  PLOUGHING: 'PLOUGHING',
  COMPACTING: 'COMPACTING',
} as const;

export interface InternalActionParcelCreate {
  parcelId: string;
  subtractionAreaHa?: number;
}

export interface InternalTelematicsActionCreate {
  actionType: InternalActionType;
  date: string;
  parcels: InternalActionParcelCreate[];
  targetCropExternalId?: string;
}

export interface InternalActionInfo {
  actionId: string;
  dosePerHa: number;
  materialId: string;
  note: string;
  unitId: string;
}

export type LocalLanguage = typeof LocalLanguage[keyof typeof LocalLanguage];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LocalLanguage = {
  EN: 'EN',
  CZ: 'CZ',
  SRB: 'SRB',
  ES: 'ES',
  RU: 'RU',
  GR: 'GR',
  RO: 'RO',
  HU: 'HU',
  PL: 'PL',
} as const;

export interface InternalActionInfoRequestTo {
  actionId: string;
  materialId: string;
}

export interface InternalActionInfoRequestBodyTo {
  actionMaterials: InternalActionInfoRequestTo[];
}

export type InternalSeedApplicationType = typeof InternalSeedApplicationType[keyof typeof InternalSeedApplicationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InternalSeedApplicationType = {
  CATCH_CROP: 'CATCH_CROP',
  MAIN_CROP: 'MAIN_CROP',
} as const;

export type InternalLandUse = typeof InternalLandUse[keyof typeof InternalLandUse];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InternalLandUse = {
  ARABLE: 'ARABLE',
  HOPFIELD: 'HOPFIELD',
  VINEYARD: 'VINEYARD',
  OTHER_PERMANENT: 'OTHER_PERMANENT',
  ORCHARD: 'ORCHARD',
  GRASS: 'GRASS',
  OTHER: 'OTHER',
  SET_ASIDE: 'SET_ASIDE',
  FODDER: 'FODDER',
  UNPRODUCTIVE: 'UNPRODUCTIVE',
  MULTIPLE_CROPS: 'MULTIPLE_CROPS',
  TRUFFLES: 'TRUFFLES',
  CONTAINERS: 'CONTAINERS',
  NURSERY: 'NURSERY',
  VEGETABLES: 'VEGETABLES',
  POND: 'POND',
  FAST_GROW: 'FAST_GROW',
  WOODED: 'WOODED',
} as const;

export interface HarvestProductTo {
  description: string;
  externalId: number;
}

export interface InternalParcelTo {
  area?: number;
  blockNumber: string;
  centerKey?: string;
  countyName?: string;
  countyUnitId?: number;
  cropInternalId?: string;
  cropLegislativeCode?: number;
  cropName?: string;
  cropSeedAppType: InternalSeedApplicationType;
  geometry?: GeoJsonPolygon;
  harvestProduct?: HarvestProductTo;
  id: string;
  landUse: InternalLandUse;
  name: string;
  square?: string;
  validFrom?: string;
  validTo?: string;
}

export type GeoJsonCoordinatesItem = { [key: string]: unknown };

export type GeoJsonType = typeof GeoJsonType[keyof typeof GeoJsonType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GeoJsonType = {
  Point: 'Point',
  MultiPoint: 'MultiPoint',
  LinearRing: 'LinearRing',
  LineString: 'LineString',
  MultiLineString: 'MultiLineString',
  Polygon: 'Polygon',
  MultiPolygon: 'MultiPolygon',
  GeometryCollection: 'GeometryCollection',
} as const;

export interface GeoJson {
  coordinates: GeoJsonCoordinatesItem[];
  type: GeoJsonType;
}

export interface GeoJsonPolygon {
  coordinates: number[][][];
  geometries: GeoJson[];
  type: GeoJsonType;
}

export interface GeoJsonMultiPoint {
  coordinates: number[][];
  type: GeoJsonType;
}

export type MaterialType = typeof MaterialType[keyof typeof MaterialType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MaterialType = {
  FERTILIZER: 'FERTILIZER',
  PLANT_PROTECTION: 'PLANT_PROTECTION',
  SEED: 'SEED',
} as const;

export interface InternalMaterialTo {
  externalId?: number;
  id: string;
  isFavourite: boolean;
  name?: string;
  type: MaterialType;
  validFrom?: string;
  validTo?: string;
}

export interface GetInternalPlantProtectionMaterialBody {
  ids?: string[];
  name?: string;
}

export type PlantProtectionEffectiveness = typeof PlantProtectionEffectiveness[keyof typeof PlantProtectionEffectiveness];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlantProtectionEffectiveness = {
  YES: 'YES',
  NO: 'NO',
  NOT_SPECIFIED: 'NOT_SPECIFIED',
} as const;

export interface ParcelPlantProtectionEffectivenessTo {
  effectiveness: PlantProtectionEffectiveness;
  parcelId: string;
}

export interface EPHMat {
  dateSoilIncorporation?: string;
  driftClass?: DriftClass;
  id?: string;
  materialId: string;
}

export interface ExpensePlantProtectionCreateTo {
  actionExpense: ActionExpenseTo;
  actionMaterial: EPHMat;
  parcelPlantProtectionEffectiveness: ParcelPlantProtectionEffectivenessTo[];
  targetOrganismsIds: string[];
}

export interface Eph {
  actionExpense: ActionExpenseTo;
  actionMaterial: EPHMat;
  materialId?: string;
}

export type DriftClass = typeof DriftClass[keyof typeof DriftClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DriftClass = {
  DRIFT_90: 'DRIFT_90',
  DRIFT_75: 'DRIFT_75',
  DRIFT_50: 'DRIFT_50',
  DRIFT_NONE: 'DRIFT_NONE',
} as const;

export interface ActionEPHCreateTo {
  date: string;
  fertilizers: Eph[];
  id?: string;
  isDraft: boolean;
  isStrawDecay: boolean;
  note?: string;
  parcels: ActionParcelCreateTo[];
  plantProtections: ExpensePlantProtectionCreateTo[];
  source: ActionSource;
  targetCropId: string;
}

export interface RevenueCreateTo {
  amount: number;
  isMainProduct: boolean;
  productLegislativeCode?: string;
}

export interface ActionHarvestCreateTo {
  date: string;
  id?: string;
  isDraft: boolean;
  note?: string;
  parcels: ActionParcelCreateTo[];
  revenues: RevenueCreateTo[];
  source: ActionSource;
}

export type ActionType = typeof ActionType[keyof typeof ActionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionType = {
  PLOW: 'PLOW',
  TILLAGE: 'TILLAGE',
  SOWING: 'SOWING',
  SOWING_PERENNIAL: 'SOWING_PERENNIAL',
  MOWING: 'MOWING',
  HARVEST: 'HARVEST',
  SPRAY: 'SPRAY',
  SPREADING_PEST: 'SPREADING_PEST',
  FERT: 'FERT',
  LIMING: 'LIMING',
  FERT_ORG: 'FERT_ORG',
  FERT_IND: 'FERT_IND',
  TANKMIX: 'TANKMIX',
  HOE: 'HOE',
  TRANSPORT: 'TRANSPORT',
  PREP: 'PREP',
  PASTURE: 'PASTURE',
  DISK: 'DISK',
  HARROW: 'HARROW',
  ROLL: 'ROLL',
  IRRIG: 'IRRIG',
  HILL: 'HILL',
  CULTIV: 'CULTIV',
  FOLIAR: 'FOLIAR',
  MULCHING: 'MULCHING',
  SUBSOILING: 'SUBSOILING',
  RAKING: 'RAKING',
  REVERSAL: 'REVERSAL',
  CRUSHING: 'CRUSHING',
  SKIDDING: 'SKIDDING',
  SUB_PLOWING: 'SUB_PLOWING',
  PLOUGHING: 'PLOUGHING',
  COMPACTING: 'COMPACTING',
} as const;

export interface InternalActionIdResponse {
  id: string;
}

export interface SeedMat {
  id?: string;
  materialId: string;
}

export type SeedApplicationType = typeof SeedApplicationType[keyof typeof SeedApplicationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SeedApplicationType = {
  CATCH_CROP: 'CATCH_CROP',
  MAIN_CROP: 'MAIN_CROP',
} as const;

export type RestrictionType = typeof RestrictionType[keyof typeof RestrictionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RestrictionType = {
  Absolute: 'Absolute',
  Boundary: 'Boundary',
  Water: 'Water',
  GroundWaterProtectionZones: 'GroundWaterProtectionZones',
  SurfaceWaterProtectionZones: 'SurfaceWaterProtectionZones',
  PopulationProtectionZones: 'PopulationProtectionZones',
  Landscape: 'Landscape',
  SlopeWater: 'SlopeWater',
  SlopeVegetationStrip: 'SlopeVegetationStrip',
} as const;

export interface RestrictionTo {
  /** Protection level for ground water or surface water */
  protectionLevel?: number;
  type: RestrictionType;
  value?: number;
}

export type EagriCropUseType = typeof EagriCropUseType[keyof typeof EagriCropUseType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EagriCropUseType = {
  NUMBER_1: '1',
  NUMBER_2: '2',
  NUMBER_3: '3',
  NUMBER_4: '4',
  NUMBER_6: '6',
  NUMBER_7: '7',
} as const;

export type ActionSource = typeof ActionSource[keyof typeof ActionSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionSource = {
  TELEMATICS: 'TELEMATICS',
  MANUAL: 'MANUAL',
  EAGRI: 'EAGRI',
  SKEAGIS: 'SKEAGIS',
  UNKNOWN: 'UNKNOWN',
} as const;

export interface ActionParcelCreateTo {
  parcelId: string;
  restrictions?: RestrictionTo[];
}

export interface ActionMowingCreateTo {
  date: string;
  id?: string;
  isDraft: boolean;
  note?: string;
  parcels: ActionParcelCreateTo[];
  revenue: RevenueCreateTo;
  source: ActionSource;
}

export interface ActionOtherCreateTo {
  actionType: ActionType;
  date: string;
  id?: string;
  isDraft: boolean;
  note?: string;
  parcels: ActionParcelCreateTo[];
  source: ActionSource;
}

export interface ActionSowingCreateTo {
  cropUseType?: EagriCropUseType;
  date: string;
  id?: string;
  isDraft: boolean;
  mainHarvestProductExternalId?: string;
  note?: string;
  parcels: ActionParcelCreateTo[];
  seedApplicationType: SeedApplicationType;
  seedExpense: Seed;
  source: ActionSource;
}

export interface ActionExpenseTo {
  amount: number;
  dosePerHa: number;
  id?: string;
  notes?: string;
  unit: string;
}

export interface Seed {
  actionExpense?: ActionExpenseTo;
  actionMaterial: SeedMat;
  materialId?: string;
}

export type FarmManagementIntegrationSource = typeof FarmManagementIntegrationSource[keyof typeof FarmManagementIntegrationSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FarmManagementIntegrationSource = {
  SKEAGIS: 'SKEAGIS',
} as const;

export interface FarmIntegrationResponse {
  source: FarmManagementIntegrationSource;
  state: string;
  validFrom: string;
}

export type IntegrationState = typeof IntegrationState[keyof typeof IntegrationState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IntegrationState = {
  DISABLED: 'DISABLED',
  ACTIVE: 'ACTIVE',
} as const;

export interface FarmSkeagisUpdateRequest {
  skeagisUserId?: string;
  state: IntegrationState;
  validFrom: string;
}

export type InternalFarmImportSource = typeof InternalFarmImportSource[keyof typeof InternalFarmImportSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InternalFarmImportSource = {
  EMPTY_FARM: 'EMPTY_FARM',
  EAGRI: 'EAGRI',
  SHP: 'SHP',
  UNKNOWN: 'UNKNOWN',
} as const;

export interface AdminFarmSettingsTo {
  eagriSettingsEnabled: boolean;
}

export interface AdminFarmTo {
  countryCode?: string;
  id: string;
  name?: string;
  settings: AdminFarmSettingsTo;
  source: InternalFarmImportSource;
}

export interface AdminFarmUpdateTo {
  name: string;
  settings: AdminFarmSettingsTo;
}

export interface ParcelGeometryOverwrite {
  differenceHa: number;
  newGeometryWkt: string;
  oldGeometryWkt: string;
  parcelId: string;
  validity: string;
}

export interface ParcelGeometryOverwriteResult {
  changes: ParcelGeometryOverwrite[];
}

export interface Unit { [key: string]: unknown }

export interface SkeagisParcelUpdateResult {
  changedParcels: string[];
  newParcels: string[];
  retiredParcels: string[];
}

export interface SkeagisFarmUpdateResult {
  farmId: string;
  parcelUpdateResult: SkeagisParcelUpdateResult;
}

export interface EagriFertilizerPropertiesDetailTo {
  applicantTradeName?: string;
  eagriId?: number;
  evidenceNumber?: string;
  fertilizerKind?: EagriFertilizerKindTo;
  fertilizerType?: string;
  isEco: boolean;
  isExcrement: boolean;
  isValid: boolean;
  nitrogenCategory: EagriFertilizerNitrogenCategoryTo;
  nitrogenCoefficient: number;
  ordinance?: string;
  price?: number;
  registrationNumber?: string;
  registrationType?: EagriFertilizerRegistrationTypeTo;
  sort?: string;
  validFrom?: string;
  validTo?: string;
}

export type UnitType = typeof UnitType[keyof typeof UnitType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnitType = {
  WEIGHT: 'WEIGHT',
  VOLUME: 'VOLUME',
} as const;

export interface FertilizerDetailTo {
  b: number;
  cao: number;
  catalogue: CatalogueTo;
  chlorides: number;
  combustibleContent: number;
  cu: number;
  description?: string;
  eagriProperties?: EagriFertilizerPropertiesDetailTo;
  electricConductivity: number;
  fe: number;
  id: string;
  isFavorite: boolean;
  isHidden: boolean;
  isOrganic: boolean;
  k2o: number;
  mgo: number;
  mn: number;
  mo: number;
  n: number;
  na2o: number;
  name: string;
  p2o5: number;
  phFrom: number;
  phTo: number;
  producerTradeName?: string;
  s: number;
  se: number;
  unitConversionCoefficient: number;
  unitType: UnitType;
  zn: number;
}

export interface FertilizerCreateTo {
  b: number;
  cao: number;
  chlorides: number;
  combustibleContent: number;
  cu: number;
  description?: string;
  eagriProperties?: EagriFertilizerPropertiesCreateTo;
  electricConductivity: number;
  fe: number;
  isHidden: boolean;
  isOrganic: boolean;
  k2o: number;
  mgo: number;
  mn: number;
  mo: number;
  n: number;
  na2o: number;
  name: string;
  p2o5: number;
  phFrom: number;
  phTo: number;
  producerTradeName?: string;
  s: number;
  se: number;
  /** Volume to mass conversion coefficient, used only when unit is in volume units (m3, l) */
  unitConversionCoefficient: number;
  unitType: UnitType;
  zn: number;
}

export interface EagriFertilizerRegistrationTypeTo {
  code: string;
  name?: string;
}

export type EagriFertilizerNitrogenCategory = typeof EagriFertilizerNitrogenCategory[keyof typeof EagriFertilizerNitrogenCategory];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EagriFertilizerNitrogenCategory = {
  NON_NITROUS: 'NON_NITROUS',
  MINERAL: 'MINERAL',
  QUICK_RELEASE: 'QUICK_RELEASE',
  SLOW_RELEASE: 'SLOW_RELEASE',
  SLUDGE: 'SLUDGE',
  PLANT_RESIDUES: 'PLANT_RESIDUES',
  SEDIMENTS: 'SEDIMENTS',
  EXCIPIENTS: 'EXCIPIENTS',
} as const;

export interface EagriFertilizerNitrogenCategoryTo {
  code: EagriFertilizerNitrogenCategory;
  name?: string;
}

export interface EagriFertilizerKindTo {
  id: number;
  name?: string;
}

export interface EagriFertilizerPropertiesCreateTo {
  applicantTradeName?: string;
  eagriId?: number;
  evidenceNumber: string;
  fertilizerKind: EagriFertilizerKindTo;
  isEco: boolean;
  isExcrement: boolean;
  nitrogenCategory: EagriFertilizerNitrogenCategoryTo;
  nitrogenCoefficient: number;
  ordinance?: string;
  price?: number;
  registrationNumber: string;
  registrationType: EagriFertilizerRegistrationTypeTo;
  sort?: string;
  validFrom?: string;
  validTo?: string;
}

export interface PlantProtectionApplicationCreateTo {
  allowedApplications?: number;
  buffers: PlantProtectionRegistrationApplicationBufferTo[];
  cropGroupId?: string;
  cropId?: string;
  id?: string;
  isAerialApplication?: boolean;
  isSeedTreatment?: boolean;
  maxBbch?: number;
  maxDose?: number;
  maxDoseWater?: number;
  maxInterval?: number;
  minBbch?: number;
  minDose?: number;
  minDoseWater?: number;
  minInterval?: number;
  notes?: string;
  organismId?: string;
  protectionPeriod?: number;
  unit?: string;
  unitWater?: string;
}

export type ApplicationBufferType = typeof ApplicationBufferType[keyof typeof ApplicationBufferType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApplicationBufferType = {
  Boundary: 'Boundary',
  Water: 'Water',
  SlopeWater: 'SlopeWater',
  SlopeVegetationStrip: 'SlopeVegetationStrip',
  Other: 'Other',
  SurfaceWaterProtectionZones: 'SurfaceWaterProtectionZones',
  GroundWaterProtectionZones: 'GroundWaterProtectionZones',
  PopulationProtectionZones: 'PopulationProtectionZones',
  Landscape: 'Landscape',
} as const;

export interface PlantProtectionRegistrationApplicationBufferTo {
  buffer?: number;
  driftReduction?: number;
  isAllowed: boolean;
  protectionLevel?: number;
  type: ApplicationBufferType;
}

export interface PlantProtectionRegistrationDataTo {
  externalId?: string;
  id: string;
  registrationHolder?: string;
}

export type CatalogueType = typeof CatalogueType[keyof typeof CatalogueType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CatalogueType = {
  EAGRI: 'EAGRI',
  GLOBAL: 'GLOBAL',
  PPA_SK: 'PPA_SK',
  PRIVATE: 'PRIVATE',
} as const;

export type CatalogueRole = typeof CatalogueRole[keyof typeof CatalogueRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CatalogueRole = {
  OWNER: 'OWNER',
  EDITOR: 'EDITOR',
  VIEWER: 'VIEWER',
} as const;

export interface CatalogueTo {
  canUpdate: boolean;
  id: string;
  role: CatalogueRole;
  type: CatalogueType;
}

export interface FertilizerTo {
  cao: number;
  catalogue: CatalogueTo;
  eagriProperties?: EagriFertilizerPropertiesTo;
  id: string;
  isFavorite: boolean;
  isHidden: boolean;
  isOrganic: boolean;
  k2o: number;
  mgo: number;
  n: number;
  name: string;
  p2o5: number;
  producerTradeName?: string;
  s: number;
}

export interface ActiveSubstanceTo {
  catalogue: CatalogueTo;
  externalId?: string;
  id: string;
  isIntegratedProductionOrchard: boolean;
  isIntegratedProductionVegetable: boolean;
  isIntegratedProductionVineyard: boolean;
  name: string;
  nameEn?: string;
}

export interface PlantProtectionDetailTo {
  activeSubstances: ActiveSubstanceTo[];
  bioFunction?: string;
  catalogue: CatalogueTo;
  externalId?: string;
  id: string;
  isEco: boolean;
  isFavorite: boolean;
  name: string;
  note?: string;
  registrations: PlantProtectionRegistrationDataTo[];
  usableUntil?: string;
}

export interface PlantProtectionActiveSubstanceCreateTo {
  activeSubstanceId: string;
  amountPerArea?: string;
  unit?: string;
}

export interface PlantProtectionCreateUpdateTo {
  activeSubstances: PlantProtectionActiveSubstanceCreateTo[];
  bioFunction?: string;
  isEco: boolean;
  isFavorite: boolean;
  name: string;
  notes?: string;
  usableUntil?: string;
}

export type EagriFullFarmUpdateMode = typeof EagriFullFarmUpdateMode[keyof typeof EagriFullFarmUpdateMode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EagriFullFarmUpdateMode = {
  DRY: 'DRY',
  BLOCKS: 'BLOCKS',
  BLOCKS_AND_PARCELS: 'BLOCKS_AND_PARCELS',
  ALL: 'ALL',
} as const;

export type HttpStatus = typeof HttpStatus[keyof typeof HttpStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HttpStatus = {
  '100_CONTINUE': '100 CONTINUE',
  '101_SWITCHING_PROTOCOLS': '101 SWITCHING_PROTOCOLS',
  '102_PROCESSING': '102 PROCESSING',
  '103_EARLY_HINTS': '103 EARLY_HINTS',
  '103_CHECKPOINT': '103 CHECKPOINT',
  '200_OK': '200 OK',
  '201_CREATED': '201 CREATED',
  '202_ACCEPTED': '202 ACCEPTED',
  '203_NON_AUTHORITATIVE_INFORMATION': '203 NON_AUTHORITATIVE_INFORMATION',
  '204_NO_CONTENT': '204 NO_CONTENT',
  '205_RESET_CONTENT': '205 RESET_CONTENT',
  '206_PARTIAL_CONTENT': '206 PARTIAL_CONTENT',
  '207_MULTI_STATUS': '207 MULTI_STATUS',
  '208_ALREADY_REPORTED': '208 ALREADY_REPORTED',
  '226_IM_USED': '226 IM_USED',
  '300_MULTIPLE_CHOICES': '300 MULTIPLE_CHOICES',
  '301_MOVED_PERMANENTLY': '301 MOVED_PERMANENTLY',
  '302_FOUND': '302 FOUND',
  '302_MOVED_TEMPORARILY': '302 MOVED_TEMPORARILY',
  '303_SEE_OTHER': '303 SEE_OTHER',
  '304_NOT_MODIFIED': '304 NOT_MODIFIED',
  '305_USE_PROXY': '305 USE_PROXY',
  '307_TEMPORARY_REDIRECT': '307 TEMPORARY_REDIRECT',
  '308_PERMANENT_REDIRECT': '308 PERMANENT_REDIRECT',
  '400_BAD_REQUEST': '400 BAD_REQUEST',
  '401_UNAUTHORIZED': '401 UNAUTHORIZED',
  '402_PAYMENT_REQUIRED': '402 PAYMENT_REQUIRED',
  '403_FORBIDDEN': '403 FORBIDDEN',
  '404_NOT_FOUND': '404 NOT_FOUND',
  '405_METHOD_NOT_ALLOWED': '405 METHOD_NOT_ALLOWED',
  '406_NOT_ACCEPTABLE': '406 NOT_ACCEPTABLE',
  '407_PROXY_AUTHENTICATION_REQUIRED': '407 PROXY_AUTHENTICATION_REQUIRED',
  '408_REQUEST_TIMEOUT': '408 REQUEST_TIMEOUT',
  '409_CONFLICT': '409 CONFLICT',
  '410_GONE': '410 GONE',
  '411_LENGTH_REQUIRED': '411 LENGTH_REQUIRED',
  '412_PRECONDITION_FAILED': '412 PRECONDITION_FAILED',
  '413_PAYLOAD_TOO_LARGE': '413 PAYLOAD_TOO_LARGE',
  '413_REQUEST_ENTITY_TOO_LARGE': '413 REQUEST_ENTITY_TOO_LARGE',
  '414_URI_TOO_LONG': '414 URI_TOO_LONG',
  '414_REQUEST_URI_TOO_LONG': '414 REQUEST_URI_TOO_LONG',
  '415_UNSUPPORTED_MEDIA_TYPE': '415 UNSUPPORTED_MEDIA_TYPE',
  '416_REQUESTED_RANGE_NOT_SATISFIABLE': '416 REQUESTED_RANGE_NOT_SATISFIABLE',
  '417_EXPECTATION_FAILED': '417 EXPECTATION_FAILED',
  '418_I_AM_A_TEAPOT': '418 I_AM_A_TEAPOT',
  '419_INSUFFICIENT_SPACE_ON_RESOURCE': '419 INSUFFICIENT_SPACE_ON_RESOURCE',
  '420_METHOD_FAILURE': '420 METHOD_FAILURE',
  '421_DESTINATION_LOCKED': '421 DESTINATION_LOCKED',
  '422_UNPROCESSABLE_ENTITY': '422 UNPROCESSABLE_ENTITY',
  '423_LOCKED': '423 LOCKED',
  '424_FAILED_DEPENDENCY': '424 FAILED_DEPENDENCY',
  '425_TOO_EARLY': '425 TOO_EARLY',
  '426_UPGRADE_REQUIRED': '426 UPGRADE_REQUIRED',
  '428_PRECONDITION_REQUIRED': '428 PRECONDITION_REQUIRED',
  '429_TOO_MANY_REQUESTS': '429 TOO_MANY_REQUESTS',
  '431_REQUEST_HEADER_FIELDS_TOO_LARGE': '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
  '451_UNAVAILABLE_FOR_LEGAL_REASONS': '451 UNAVAILABLE_FOR_LEGAL_REASONS',
  '500_INTERNAL_SERVER_ERROR': '500 INTERNAL_SERVER_ERROR',
  '501_NOT_IMPLEMENTED': '501 NOT_IMPLEMENTED',
  '502_BAD_GATEWAY': '502 BAD_GATEWAY',
  '503_SERVICE_UNAVAILABLE': '503 SERVICE_UNAVAILABLE',
  '504_GATEWAY_TIMEOUT': '504 GATEWAY_TIMEOUT',
  '505_HTTP_VERSION_NOT_SUPPORTED': '505 HTTP_VERSION_NOT_SUPPORTED',
  '506_VARIANT_ALSO_NEGOTIATES': '506 VARIANT_ALSO_NEGOTIATES',
  '507_INSUFFICIENT_STORAGE': '507 INSUFFICIENT_STORAGE',
  '508_LOOP_DETECTED': '508 LOOP_DETECTED',
  '509_BANDWIDTH_LIMIT_EXCEEDED': '509 BANDWIDTH_LIMIT_EXCEEDED',
  '510_NOT_EXTENDED': '510 NOT_EXTENDED',
  '511_NETWORK_AUTHENTICATION_REQUIRED': '511 NETWORK_AUTHENTICATION_REQUIRED',
} as const;

export interface EagriAuthValidationResultTo {
  farmName?: string;
  message?: string;
  status: HttpStatus;
  success: boolean;
}

export type EagriServiceEnvironment = typeof EagriServiceEnvironment[keyof typeof EagriServiceEnvironment];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EagriServiceEnvironment = {
  PRODUCTION: 'PRODUCTION',
  TEST: 'TEST',
} as const;

export interface EagriAuthDataValidationResponseTo {
  environment?: EagriServiceEnvironment;
  login?: string;
  szrid?: string;
  validation: EagriAuthValidationResultTo;
}

export interface EagriAuthDataValidationRequestTo {
  environment?: EagriServiceEnvironment;
  login: string;
  secret: string;
  szrid: string;
}

export interface SowingPlanParcelCropBody {
  cropId?: string;
  seasonId: string;
}

export interface BatchCropSowingPlanCreate {
  cropId?: string;
  parcelId: string;
  seasonId: string;
}

export interface BulkSowingPlanParcelCropBody {
  parcelCrops: BatchCropSowingPlanCreate[];
}

export interface InternalCenterTo {
  id: string;
  key: string;
  name: string;
}

export interface InternalCenterCreateTo {
  key: string;
  name: string;
}





  /**
 * @summary Updates centers (batch)
 */
export const updateCenters = <TData = AxiosResponse<InternalCenterTo[]>>(
    internalCenterCreateTo: InternalCenterCreateTo[],
    params: UpdateCentersParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/internal-api/parcels/centers`,
      internalCenterCreateTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getDetailedParcelWithSowingPlan = <TData = AxiosResponse<ParcelWithSowingPlansTo[]>>(
    params: GetDetailedParcelWithSowingPlanParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/sowing-plan/parcels`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const putCropsToParcelAndSeason = <TData = AxiosResponse<void>>(
    bulkSowingPlanParcelCropBody: BulkSowingPlanParcelCropBody,
    params: PutCropsToParcelAndSeasonParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/sowing-plan/parcels`,
      bulkSowingPlanParcelCropBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getParcelSowingPlan1 = <TData = AxiosResponse<SowingPlanSeasonCropTo[]>>(
    parcelId: number,
    params: GetParcelSowingPlan1Params, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/sowing-plan/parcels/${parcelId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const putCropToParcelAndSeason = <TData = AxiosResponse<void>>(
    parcelId: number,
    sowingPlanParcelCropBody: SowingPlanParcelCropBody,
    params: PutCropToParcelAndSeasonParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/sowing-plan/parcels/${parcelId}`,
      sowingPlanParcelCropBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * 
            Validates eAgri credentials for the farm. 
            If validation is successful, returns farm name from eAgri, otherwise returns error message and status. 
            In any case, returns farm szrid and login, if they are stored. Secret (WS key) is never returned in the response.
        
 * @summary Returns farm eAgri credentials
 */
export const getEagriAuth = <TData = AxiosResponse<EagriAuthDataValidationResponseTo>>(
    farmId: number,
    params?: GetEagriAuthParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/farms/settings/${farmId}/eagri-auth`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * 
            Validates eAgri credentials for the farm and stores them if validation is successful.
        
 * @summary Validas and stores farm eAgri credentials
 */
export const putEagriAuth = <TData = AxiosResponse<EagriAuthDataValidationResponseTo>>(
    farmId: number,
    eagriAuthDataValidationRequestTo: EagriAuthDataValidationRequestTo,
    params?: PutEagriAuthParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/farms/settings/${farmId}/eagri-auth`,
      eagriAuthDataValidationRequestTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * 
            Updates a farm using eAGRI service. Farms created using SHP or empty foreign farms are not allowed to use this endpoint.
            At most one concurrent update per farm is permitted.
            
            Farm update also automatically synchronizes eAGRI catalogues. If you wish to turn off this behaviour,
            set request parameter sync-catalogues to false.
            
            Note that update logic itself, i.e. logic beyond concurrency and eligibility checks, is asynchronous.
            If you wish to see current update status, use /api/farms/updated.
            
 * @summary Updates a farm using eAGRI webservices.
 */
export const updateFarm = <TData = AxiosResponse<void>>(
    farmId: number,
    params?: UpdateFarmParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/farms/eagri/${farmId}`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Updates plant protection product in PRIVATE catalogue.
 * @summary Update plant protection
 */
export const putPlantProtection = <TData = AxiosResponse<PlantProtectionDetailTo>>(
    plantProtectionId: string,
    plantProtectionCreateUpdateTo: PlantProtectionCreateUpdateTo,
    params: PutPlantProtectionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/plant-protections/${plantProtectionId}`,
      plantProtectionCreateUpdateTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Updates plant protection product in PRIVATE catalogue.
 * @summary Update plant protection
 */
export const patchPlantProtection = <TData = AxiosResponse<void>>(
    plantProtectionId: string,
    plantProtectionPatchTo: PlantProtectionPatchTo,
    params: PatchPlantProtectionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/plant-protections/${plantProtectionId}`,
      plantProtectionPatchTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Update plant protection application
 */
export const putPlantProtectionRegistrationApplication = <TData = AxiosResponse<void>>(
    plantProtectionId: string,
    applicationId: number,
    plantProtectionApplicationCreateTo: PlantProtectionApplicationCreateTo,
    params: PutPlantProtectionRegistrationApplicationParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/plant-protections/${plantProtectionId}/applications/${applicationId}`,
      plantProtectionApplicationCreateTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Delete plant protection application
 */
export const deletePlantProtectionRegistrationApplication = <TData = AxiosResponse<void>>(
    plantProtectionId: string,
    applicationId: number,
    params: DeletePlantProtectionRegistrationApplicationParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/plant-protections/${plantProtectionId}/applications/${applicationId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Returns fertilizer detail.
 * @summary Fertilizer detail
 */
export const getFertilizer = <TData = AxiosResponse<FertilizerDetailTo>>(
    fertilizerId: number,
    params: GetFertilizerParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/fertilizers/${fertilizerId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Update fertilizer
 */
export const putFertilizer = <TData = AxiosResponse<FertilizerDetailTo>>(
    fertilizerId: number,
    fertilizerCreateTo: FertilizerCreateTo,
    params: PutFertilizerParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/fertilizers/${fertilizerId}`,
      fertilizerCreateTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Hidden fertilizers are not deleted, and can be un-hidden by PUT request.
 * @summary Hides fertilizer
 */
export const hideFertilizer = <TData = AxiosResponse<FertilizerDetailTo>>(
    fertilizerId: number,
    fertilizerPatchTo: FertilizerPatchTo,
    params: HideFertilizerParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/fertilizers/${fertilizerId}`,
      fertilizerPatchTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const updateSkeagisFarm = <TData = AxiosResponse<SkeagisFarmUpdateResult>>(
    params: UpdateSkeagisFarmParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/management/farm/update-skeagis-farm`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const recomputeFarmGeometries = <TData = AxiosResponse<Unit[]>>(
    params: RecomputeFarmGeometriesParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/management/farm/recompute-farm-geometries`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const overwriteParcelGeometries = <TData = AxiosResponse<ParcelGeometryOverwriteResult>>(
    params: OverwriteParcelGeometriesParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/management/farm/overwrite-parcel-geometries`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Returns detail of a farm user is authorized.
 * @summary Returns farm detail
 */
export const getFarmDetail2 = <TData = AxiosResponse<AdminFarmTo>>(
    farmId: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/farms/${farmId}`,options
    );
  }

/**
 * @summary Update farm
 */
export const putFarm = <TData = AxiosResponse<AdminFarmTo>>(
    farmId: number,
    adminFarmUpdateTo: AdminFarmUpdateTo, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/farms/${farmId}`,
      adminFarmUpdateTo,options
    );
  }

export const updateFarmCurrentParcels = <TData = AxiosResponse<void>>(
    farmId: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/farms/${farmId}/eagri/current-parcels`,undefined,options
    );
  }

export const updateAllFarmsCurrentParcels = <TData = AxiosResponse<void>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/farms/eagri/current-parcels`,undefined,options
    );
  }

/**
 * @summary Retires all current parcels and imports parcels from shapefile into existing farm. Does not work with CZ farms or farms with active integration.For internal use by PZ team.Parcel historization not handled.
 */
export const updateFarmFromShapefile = <TData = AxiosResponse<unknown>>(
    updateFarmFromShapefileBody: UpdateFarmFromShapefileBody,
    params: UpdateFarmFromShapefileParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/farm/shapefile`,
      updateFarmFromShapefileBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const updateSkeagisState = <TData = AxiosResponse<FarmIntegrationResponse>>(
    farmSkeagisUpdateRequest: FarmSkeagisUpdateRequest,
    params: UpdateSkeagisStateParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/farm/integrations/skeagis`,
      farmSkeagisUpdateRequest,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const createNewSkeagisIntegration = <TData = AxiosResponse<FarmIntegrationResponse>>(
    farmSkeagisCreateRequest: FarmSkeagisCreateRequest,
    params: CreateNewSkeagisIntegrationParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/farm/integrations/skeagis`,
      farmSkeagisCreateRequest,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const updateSowingAction = <TData = AxiosResponse<InternalActionIdResponse>>(
    actionSowingCreateTo: ActionSowingCreateTo,
    params: UpdateSowingActionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/actions/sowing`,
      actionSowingCreateTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const createSowingAction = <TData = AxiosResponse<InternalActionIdResponse>>(
    actionSowingCreateTo: ActionSowingCreateTo,
    params: CreateSowingActionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/actions/sowing`,
      actionSowingCreateTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const updateOtherAction = <TData = AxiosResponse<InternalActionIdResponse>>(
    actionOtherCreateTo: ActionOtherCreateTo,
    params: UpdateOtherActionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/actions/other`,
      actionOtherCreateTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const createOtherAction = <TData = AxiosResponse<InternalActionIdResponse>>(
    actionOtherCreateTo: ActionOtherCreateTo,
    params: CreateOtherActionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/actions/other`,
      actionOtherCreateTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const updateMowingAction = <TData = AxiosResponse<InternalActionIdResponse>>(
    actionMowingCreateTo: ActionMowingCreateTo,
    params: UpdateMowingActionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/actions/mowing`,
      actionMowingCreateTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const createMowingAction = <TData = AxiosResponse<InternalActionIdResponse>>(
    actionMowingCreateTo: ActionMowingCreateTo,
    params: CreateMowingActionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/actions/mowing`,
      actionMowingCreateTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const updateHarvestAction = <TData = AxiosResponse<InternalActionIdResponse>>(
    actionHarvestCreateTo: ActionHarvestCreateTo,
    params: UpdateHarvestActionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/actions/harvest`,
      actionHarvestCreateTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const createHarvestAction = <TData = AxiosResponse<InternalActionIdResponse>>(
    actionHarvestCreateTo: ActionHarvestCreateTo,
    params: CreateHarvestActionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/actions/harvest`,
      actionHarvestCreateTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const updateEPHAction = <TData = AxiosResponse<InternalActionIdResponse>>(
    actionEPHCreateTo: ActionEPHCreateTo,
    params: UpdateEPHActionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/actions/eph`,
      actionEPHCreateTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const createEPHAction = <TData = AxiosResponse<InternalActionIdResponse>>(
    actionEPHCreateTo: ActionEPHCreateTo,
    params: CreateEPHActionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/actions/eph`,
      actionEPHCreateTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getPlantProtectionsInternal = <TData = AxiosResponse<InternalMaterialTo[]>>(
    getInternalPlantProtectionMaterialBody: GetInternalPlantProtectionMaterialBody,
    params: GetPlantProtectionsInternalParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/internal-api/plant-protections`,
      getInternalPlantProtectionMaterialBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Returns list of parcels intersecting with given geometry.
 * @summary Returns list of parcels
 */
export const getParcelIntersections = <TData = AxiosResponse<InternalParcelTo[]>>(
    geoJsonMultiPoint: GeoJsonMultiPoint,
    params: GetParcelIntersectionsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/internal-api/parcels/intersecting`,
      geoJsonMultiPoint,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getActionInfo = <TData = AxiosResponse<InternalActionInfo[]>>(
    internalActionInfoRequestBodyTo: InternalActionInfoRequestBodyTo,
    params: GetActionInfoParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/internal-api/actions/notes`,
      internalActionInfoRequestBodyTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const createDraftAction = <TData = AxiosResponse<InternalActionIdResponse>>(
    internalTelematicsActionCreate: InternalTelematicsActionCreate,
    params: CreateDraftActionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/internal-api/actions/draft`,
      internalTelematicsActionCreate,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getSeasons = <TData = AxiosResponse<SowingPlanSeasonTo[]>>(
    params: GetSeasonsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/sowing-plan/seasons`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const createMissingSeasons = <TData = AxiosResponse<SowingPlanSeasonTo[]>>(
    params: CreateMissingSeasonsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/sowing-plan/seasons`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Sends por use report to eagri, for given date range.
 */
export const sendPorUserReportToEagri = <TData = AxiosResponse<PlantProtectionPairingResultTo>>(
    params: SendPorUserReportToEagriParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/reports/eagri/por-usage`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary This is for testing, dont use it on FE. Returns body that would be sent to eagri.
 */
export const getEagriPorUseReportBody = <TData = AxiosResponse<PorUseBody>>(
    params: GetEagriPorUseReportBodyParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/reports/eagri/por-usage/dry`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * You can use `stopBeforeSending` queryParam to test the report without sending it to eagri.
 * @summary Sends fertilizer use report to eagri, for given date range.
 */
export const sendFertilizerUserReportToEagri = <TData = AxiosResponse<EagriReportResponse>>(
    eagriReportBody: EagriReportBody,
    params: SendFertilizerUserReportToEagriParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/reports/eagri/fertilizer-usage`,
      eagriReportBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary This is for testing, dont use it on FE. Returns fertilizer use report data that would be sent to eagri.
 */
export const getFertilizerUserReportBody = <TData = AxiosResponse<FertiliserUseBody>>(
    eagriReportBody: EagriReportBody,
    params: GetFertilizerUserReportBodyParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/reports/eagri/fertilizer-usage/dry`,
      eagriReportBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Creates customer, new schema and imports shapefile
 */
export const registerWithShapeFile = <TData = AxiosResponse<unknown>>(
    registerWithShapeFileBody: RegisterWithShapeFileBody,
    params: RegisterWithShapeFileParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/registration/shapefile`,
      registerWithShapeFileBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Validates if shapefile contains all files
 */
export const validateShapefile = <TData = AxiosResponse<unknown>>(
    validateShapefileBody: ValidateShapefileBody,
    params: ValidateShapefileParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/registration/shapefile/validate`,
      validateShapefileBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary 
            Submits and awaits an empty farm creation. This involves the following steps:
            - create a new customer
            - create an empty farm (i.e. farm without external data layers)
        "
 */
export const registerEmptyFarm = <TData = AxiosResponse<unknown>>(
    params: RegisterEmptyFarmParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/registration/empty`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary 
            Asynchronously submits a farm creation task and imports farm data from eAGRI. This involves the following steps:
            - create a customer
            - create an empty farm
            - request farm DPB data from the eAGRI portal and parse them into blocks + parcels
            - persist parsed data into database
        "
 */
export const registerWithEagri = <TData = AxiosResponse<unknown>>(
    params: RegisterWithEagriParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/registration/eagri`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getParcelsSubtraction = <TData = AxiosResponse<SubtractionTo>>(
    parcelId: number,
    restrictionTo: RestrictionTo,
    params: GetParcelsSubtractionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/parcels/${parcelId}/subtractions`,
      restrictionTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns all zones
 */
export const getZones = <TData = AxiosResponse<ZoneDetailTo[]>>(
    params: GetZonesParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/parcels/zones`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Creates new zone
 */
export const createZone = <TData = AxiosResponse<ZoneDetailTo>>(
    zoneCreateTo: ZoneCreateTo,
    params: CreateZoneParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/parcels/zones`,
      zoneCreateTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @deprecated
 */
export const recountSubtractionsForAction = <TData = AxiosResponse<ParcelSubtractionResponse[]>>(
    recountSubtractionRequest: RecountSubtractionRequest,
    params: RecountSubtractionsForActionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/parcels/subtractions`,
      recountSubtractionRequest,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getParcelSubtractionGeometry = <TData = AxiosResponse<ParcelGeometryResponse>>(
    parcelRestrictionTo: ParcelRestrictionTo,
    params: GetParcelSubtractionGeometryParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/parcels/subtractions/geometry`,
      parcelRestrictionTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getParcelAreaAfterSubtraction = <TData = AxiosResponse<ParcelAreaTo[]>>(
    parcelRestrictionRequestBody: ParcelRestrictionRequestBody,
    params: GetParcelAreaAfterSubtractionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/parcels/subtractions/area`,
      parcelRestrictionRequestBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Creates a custom parcel created by user in our application.
 * @summary Create parcel
 */
export const create = <TData = AxiosResponse<CustomParcelCreateResult>>(
    customParcelCreateRequest: CustomParcelCreateRequest,
    params: CreateParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/manual/parcel`,
      customParcelCreateRequest,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Get available updates. Available updates have a certain TTL and will therefore be deleted after a certain period of time.
 */
export const getAvailableUpdates = <TData = AxiosResponse<FarmUpdateChangesResponse>>(
    params: GetAvailableUpdatesParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/farms/eagri/available-updates`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Requests available farm update search. The search itself is done asynchronously; to retrieve search results, use the corresponding GET method. Please note there is a timeout period for which we try to download eAGRI data; if there are no data available after this period, this most likely means that eAGRI services are not available at the moment.
 */
export const requestAvailableUpdates = <TData = AxiosResponse<void>>(
    params: RequestAvailableUpdatesParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/farms/eagri/available-updates`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Attempts to store eAGRI authentication credentials. Data are associated with the current user.
 */
export const storeAuthData = <TData = AxiosResponse<EagriAuthValidationResultTo>>(
    eagriAuthDataValidationRequestTo: EagriAuthDataValidationRequestTo, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/eagri-auth`,
      eagriAuthDataValidationRequestTo,options
    );
  }

/**
 * Returns farm seed catalogue.
 * @summary List seeds
 */
export const getSeeds = <TData = AxiosResponse<CropSeedTo[]>>(
    params: GetSeedsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/seeds`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * 
            <p>Adds seed to farm catalogue.</p>
            <ul>
                <li>If seed with same variety name already exists, will just return the existing seed</li>
                <li>If seed exist but is hidden, will un-hide it</li>
            </ul>
        
 * @summary Add seed
 */
export const postSeed = <TData = AxiosResponse<CropSeedTo>>(
    cropSeedCreateTo: CropSeedCreateTo,
    params: PostSeedParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/seeds`,
      cropSeedCreateTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Creates plant protection product in PRIVATE catalogue.
 * @summary Create plant protection
 */
export const postPlantProtection = <TData = AxiosResponse<PlantProtectionDetailTo>>(
    plantProtectionCreateUpdateTo: PlantProtectionCreateUpdateTo,
    params: PostPlantProtectionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/plant-protections`,
      plantProtectionCreateUpdateTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Copies plant protection with applications to private catalogue. Copy doesn't copy registrations only their applications by registration id
 * @summary Copy plant protection
 */
export const copyPlantProtection = <TData = AxiosResponse<PlantProtectionDetailTo>>(
    plantProtectionId: string,
    params: CopyPlantProtectionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/plant-protections/${plantProtectionId}/copy`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Create plant protection application
 */
export const postPlantProtectionRegistrationApplication = <TData = AxiosResponse<void>>(
    plantProtectionId: string,
    plantProtectionApplicationCreateTo: PlantProtectionApplicationCreateTo,
    params: PostPlantProtectionRegistrationApplicationParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/plant-protections/${plantProtectionId}/applications`,
      plantProtectionApplicationCreateTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getOrganisms = <TData = AxiosResponse<OrganismTo[]>>(
    params: GetOrganismsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/plant-protection/organisms`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const createOrganisms = <TData = AxiosResponse<OrganismTo>>(
    createOrganismRequestTo: CreateOrganismRequestTo,
    params: CreateOrganismsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/plant-protection/organisms`,
      createOrganismRequestTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getActiveSubstances = <TData = AxiosResponse<ActiveSubstanceTo[]>>(
    params: GetActiveSubstancesParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/plant-protection/active-substances`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const postActiveSubstance = <TData = AxiosResponse<ActiveSubstanceTo>>(
    activeSubstanceCreateTo: ActiveSubstanceCreateTo,
    params: PostActiveSubstanceParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/plant-protection/active-substances`,
      activeSubstanceCreateTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Returns farm fertilizer catalogue.
 * @summary List fertilizers
 */
export const getFertilizers = <TData = AxiosResponse<FertilizerTo[]>>(
    params: GetFertilizersParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/fertilizers`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Adds fertilizer to farm catalogue.
 * @summary Add fertilizer
 */
export const postFertilizer = <TData = AxiosResponse<FertilizerDetailTo>>(
    fertilizerCreateTo: FertilizerCreateTo,
    params: PostFertilizerParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/fertilizers`,
      fertilizerCreateTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Expected file in .zip. Will return forbidden if file has more than one shapefile.As srid in .prj file might be wrong, specify srid of provided shp file explicitly
 * @summary Creates a new version of surface water protection zones geo layer
 */
export const importSurfaceWaterProtectionZones = <TData = AxiosResponse<void>>(
    importSurfaceWaterProtectionZonesBody: ImportSurfaceWaterProtectionZonesBody,
    params: ImportSurfaceWaterProtectionZonesParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/water-protection-zones/surface`,
      importSurfaceWaterProtectionZonesBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Expected file in .zip. Will return forbidden if file has more than one shapefile. As srid in .prj file might be wrong, specify srid of provided shp file explicitly
 * @summary Creates a new version of ground water protection zones geo layer
 */
export const importGroundWaterProtectionZones = <TData = AxiosResponse<void>>(
    importGroundWaterProtectionZonesBody: ImportGroundWaterProtectionZonesBody,
    params: ImportGroundWaterProtectionZonesParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/water-protection-zones/ground`,
      importGroundWaterProtectionZonesBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const migrateStoreIds = <TData = AxiosResponse<void>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/remove-not-contains`,undefined,options
    );
  }

export const syncCounties = <TData = AxiosResponse<void>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/management/sync-counties`,undefined,options
    );
  }

export const repairSeedApplications = <TData = AxiosResponse<void>>(
    params: RepairSeedApplicationsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/management/repair-seed-apps`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Updates nitrate vulnerable zones for on parcels on all CZ farms. Warning: it may take a 1 hour or more time to finish! It is preferable to run the update on farm by farm basis.
 * @summary Updates nitrate vulnerable flags
 */
export const updateNitrateVulnerable = <TData = AxiosResponse<AdminFarmManagementTo[]>>(
    params?: UpdateNitrateVulnerableParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/management/nitrate-vulnerable-zones`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Returns list of nitrate vulnerable status for all parcels on farm without updating the database.
 * @summary Returns nitrate vulnerable status
 */
export const getNitrateVulnerableOnFarm = <TData = AxiosResponse<AdminNitrateVulnerableZoneTo[]>>(
    farmId: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/management/nitrate-vulnerable-zones/${farmId}`,options
    );
  }

/**
 * Updates nitrate vulnerable status for all parcels on farm.
 * @summary Updates nitrate vulnerable flags
 */
export const postNitrateVulnerableOnFarm = <TData = AxiosResponse<AdminNitrateVulnerableZoneTo[]>>(
    farmId: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/management/nitrate-vulnerable-zones/${farmId}`,undefined,options
    );
  }

/**
 * 
            By default, synchronization is performed automatically after a successful update.
            However, if the satellite service fails to synchronize its parcel data, this endpoint
            allows an admin to run the sync manually.
        
 * @summary Manually syncs precision farming parcels.
 */
export const satelliteUpdateSync = <TData = AxiosResponse<void>>(
    params: SatelliteUpdateSyncParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/management/farm/update-sync`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * 
            The motivation behind having this endpoint is that after LPIS update, a lot of parcels
            appear unsown because sowings applicable to such parcels are attached to retired parcels;
            in reality, there might be no harvest on the retired parcel.
        
 * @summary Moves parcel validity according to the sowing of its predecessors and moves actions accordingly.
 */
export const moveParcelValiditiesToNewestSowing = <TData = AxiosResponse<AdminParcelValidityMoveResult>>(
    params: MoveParcelValiditiesToNewestSowingParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/management/farm/move-parcel-valid-from`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * 
            Action moving is done automatically as a part of update; this endpoint serves
            as a helper service for historical data which for some reason weren't moved.
        
 * @summary Moves actions after update.
 */
export const moveActionsAfterUpdate = <TData = AxiosResponse<AdminActionMoveResult>>(
    params: MoveActionsAfterUpdateParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/management/farm/move-actions`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Creates a new eAGRI farm using data from an eAGRI test user account.
 */
export const createTestEagriFarm = <TData = AxiosResponse<void>>(
    adminEagriTestFarmCreateRequest: AdminEagriTestFarmCreateRequest, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/management/farm/create-test-farm`,
      adminEagriTestFarmCreateRequest,options
    );
  }

/**
 * @deprecated
 */
export const refreshFarmCache = <TData = AxiosResponse<void>>(
    farmId: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/geoserver/farm/${farmId}/refresh-farm-cache`,undefined,options
    );
  }

/**
 * Returns preview of LPIS block information for current versions of farm LPIS blocks from eAgri.
 * @summary Returns LPIS preview
 */
export const getLpisBlocks = <TData = AxiosResponse<unknown>>(
    farmId: number,
    params?: GetLpisBlocksParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/farms/${farmId}/lpis`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Synchronizes current versions of LPIS blocks with eAgri. It does not update LPIS block if they were retired.
 * @summary Updates LPIS info
 */
export const updateEagriFarm = <TData = AxiosResponse<unknown>>(
    farmId: number,
    params?: UpdateEagriFarmParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/farms/${farmId}/lpis`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const deleteNonTerminalActionSeedApps = <TData = AxiosResponse<void>>(
    farmId: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/farms/${farmId}/actions/delete-non-terminal-action-seed-apps`,undefined,options
    );
  }

export const assignCorrectSeedAppsToAllParcelsActionParcels = <TData = AxiosResponse<void>>(
    farmId: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/farms/${farmId}/actions/assign-correct-seed-apps`,undefined,options
    );
  }

export const addSowingPlanToCurrentSeason = <TData = AxiosResponse<void>>(
    params?: AddSowingPlanToCurrentSeasonParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/farms/add-current-crop-to-current-season`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const moveAllInitialSeedAppsToStart = <TData = AxiosResponse<void>>(
    params?: MoveAllInitialSeedAppsToStartParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/farms/actions/move-initial-to-start`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const fixEagriActionCrops = <TData = AxiosResponse<void>>(
    params: FixEagriActionCropsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/farms/actions/fix-eagri-seeds`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Synchronizes eAgri catalogue
 */
export const postSync = <TData = AxiosResponse<void>>(
    catalogueType: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/catalogues/eagri/sync/${catalogueType}`,undefined,options
    );
  }

export const getSeedApplicationFertilization = <TData = AxiosResponse<ParcelAccountableNitrogenTo[]>>(
    targetSeedApplicationFertilizationRequestTo: TargetSeedApplicationFertilizationRequestTo,
    params: GetSeedApplicationFertilizationParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/actions/target-seed-application/accountable-nitrogen`,
      targetSeedApplicationFertilizationRequestTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const splitAction = <TData = AxiosResponse<SplitActionResponse>>(
    splitActionRequest: SplitActionRequest,
    params: SplitActionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/actions/split`,
      splitActionRequest,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const validateEPHAction = <TData = AxiosResponse<ValidationResponseTo>>(
    actionEPHCreateTo: ActionEPHCreateTo,
    params: ValidateEPHActionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/actions/eph/restrictions`,
      actionEPHCreateTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Returns parcel detail.
 * @summary Parcel detail
 */
export const getParcel = <TData = AxiosResponse<ParcelDetailTo>>(
    parcelId: number,
    params: GetParcelParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/parcels/${parcelId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Updates parcel name or/and user yield grade.
 * @summary Update parcel
 */
export const patchParcel = <TData = AxiosResponse<void>>(
    parcelId: number,
    parcelUpdateTo: ParcelUpdateTo,
    params: PatchParcelParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/parcels/${parcelId}`,
      parcelUpdateTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Deletes existing zone
 */
export const deleteZone = <TData = AxiosResponse<void>>(
    zoneId: number,
    params: DeleteZoneParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/parcels/zones/${zoneId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Updates existing zone
 */
export const updateZone = <TData = AxiosResponse<ZoneDetailTo>>(
    zoneId: number,
    zoneCreateTo: ZoneCreateTo,
    params: UpdateZoneParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/parcels/zones/${zoneId}`,
      zoneCreateTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Get seed by id
 */
export const getSeed = <TData = AxiosResponse<CropSeedTo>>(
    seedId: number,
    params: GetSeedParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/seeds/${seedId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Updates seed variety name or sets/unsets seed hidden status.
 * @summary Update seed
 */
export const patchSeed = <TData = AxiosResponse<CropSeedTo>>(
    seedId: number,
    cropSeedPatchTo: CropSeedPatchTo,
    params: PatchSeedParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.patch(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/seeds/${seedId}`,
      cropSeedPatchTo,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Get public parcels by farm, zone and status, lang is used for language of current crop
 * @summary Get public parcels
 */
export const getParcelByArealName = <TData = AxiosResponse<PublicParcelTo[]>>(
    params: GetParcelByArealNameParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/public-api/parcels`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getCropsInternal = <TData = AxiosResponse<InternalMaterialTo[]>>(
    params: GetCropsInternalParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/internal-api/seeds`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getParcelSowingPlan = <TData = AxiosResponse<InternalSeasonTo[]>>(
    parcelId: number,
    params: GetParcelSowingPlanParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/internal-api/seasons/parcels/${parcelId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Returns parcels valid in specified date range. By default, parcels valid on today's date are returned.
 * @summary Returns list of parcels
 */
export const getParcels = <TData = AxiosResponse<InternalParcelTo[]>>(
    params: GetParcelsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/internal-api/parcels`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Returns detail of parcel by id.
 * @summary Returns parcel detail
 */
export const getParcelDetail = <TData = AxiosResponse<InternalParcelTo>>(
    parcelId: number,
    params: GetParcelDetailParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/internal-api/parcels/${parcelId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getSeedApplications = <TData = AxiosResponse<InternalSeedApplicationTo[]>>(
    parcelId: number,
    params: GetSeedApplicationsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/internal-api/parcels/${parcelId}/seed-applications`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getFertilizersInternal = <TData = AxiosResponse<InternalMaterialTo[]>>(
    params: GetFertilizersInternalParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/internal-api/fertilizers`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getFertilizerMineralIntake = <TData = AxiosResponse<InternalFertilizerMineralIntakeResponse>>(
    fertilizerId: number,
    params: GetFertilizerMineralIntakeParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/internal-api/fertilizers/${fertilizerId}/dose`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns farm detail.
 */
export const getFarmDetail = <TData = AxiosResponse<InternalFarmResponse>>(
    params: GetFarmDetailParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/internal-api/farms`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getFarmUsers = <TData = AxiosResponse<InternalFarmUserResponse[]>>(
    params: GetFarmUsersParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/internal-api/farms/users`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getUserFarms = <TData = AxiosResponse<InternalFarmResponse[]>>(
    params: GetUserFarmsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/internal-api/farms/account`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getCropsInternal1 = <TData = AxiosResponse<InternalCropTo[]>>(
    params: GetCropsInternal1Params, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/internal-api/crops`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getCropInternal = <TData = AxiosResponse<InternalCropTo>>(
    id: number,
    params?: GetCropInternalParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/internal-api/crops/${id}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns farm catalogue types
 */
export const getCropCatalogueType = <TData = AxiosResponse<CropCatalogueType>>(
    params: GetCropCatalogueTypeParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/internal-api/catalogues/crops`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getSowingPlanStatistics = <TData = AxiosResponse<SowingPlanSeasonStatisticsTo>>(
    seasonId: number,
    params: GetSowingPlanStatisticsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/sowing-plan/seasons/${seasonId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getSeasonsExcel = <TData = AxiosResponse<Blob>>(
    params: GetSeasonsExcelParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/sowing-plan/seasons/excel`,{
        responseType: 'blob',
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getSeedApplicationsAtDate = <TData = AxiosResponse<SeedApplicationTo[]>>(
    params: GetSeedApplicationsAtDateParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/seed-applications/at-date`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getSeededAreas = <TData = AxiosResponse<string>>(
    params: GetSeededAreasParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/reports/seeded-areas`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getSeedApplicationsReport = <TData = AxiosResponse<string>>(
    params: GetSeedApplicationsReportParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/reports/seed-applications`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getRevenuesReport = <TData = AxiosResponse<string>>(
    params: GetRevenuesReportParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/reports/revenues`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * 
            Returns crop rotation restriction violations base on parcel LPIS codes. 
            The validation includes validation of seed applications on historic parcels and validation of sowing plans
            on current parcels where seed applications are not yet available.
        
 * @summary Validates crop rotation restrictions
 */
export const getRestrictionsSowingReport = <TData = AxiosResponse<ValidationResponseTo>>(
    params: GetRestrictionsSowingReportParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/reports/restrictions/sowing`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getPorUsageReport = <TData = AxiosResponse<string>>(
    params: GetPorUsageReportParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/reports/plant-protection-usage`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getPorConsumptionByPPReport = <TData = AxiosResponse<string>>(
    params: GetPorConsumptionByPPReportParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/reports/plant-protection-consumption`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getPorConsumptionByCropReport = <TData = AxiosResponse<string>>(
    params: GetPorConsumptionByCropReportParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/reports/plant-protection-consumption-crop`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getParcels1 = <TData = AxiosResponse<Blob>>(
    params: GetParcels1Params, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/reports/parcels`,{
        responseType: 'blob',
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getReports = <TData = AxiosResponse<ReportHistoryTo[]>>(
    params: GetReportsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/reports/history`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getFertilizerUsageReport = <TData = AxiosResponse<string>>(
    params: GetFertilizerUsageReportParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/reports/fertilizer-usage`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getFertilizerOrganicReport = <TData = AxiosResponse<string>>(
    params: GetFertilizerOrganicReportParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/reports/fertilizer-organic`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getFertilizerConsumptionReport = <TData = AxiosResponse<string>>(
    params: GetFertilizerConsumptionReportParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/reports/fertilizer-consumption`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getFertilizerConsumptionByCropReport = <TData = AxiosResponse<string>>(
    params: GetFertilizerConsumptionByCropReportParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/reports/fertilizer-consumption-crop`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getFarmNitrogenReport = <TData = AxiosResponse<string>>(
    params: GetFarmNitrogenReportParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/reports/farm-nitrogen`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getCvsReport = <TData = AxiosResponse<HttpServletResponse>>(
    params: GetCvsReportParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/reports/eagri/sowing-plan`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Fetches pdf errors report from eagri for given guid.
 */
export const getEagriPorUseErrors = <TData = AxiosResponse<string>>(
    params: GetEagriPorUseErrorsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/reports/eagri/por-usage/errors`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Fetches report errors from eagri given guid
 */
export const checkFertilizerUserReport = <TData = AxiosResponse<Response>>(
    params: CheckFertilizerUserReportParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/reports/eagri/fertilizer-usage/errors`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getActionsReport = <TData = AxiosResponse<string>>(
    params: GetActionsReportParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/reports/actions`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Returns list of parcels by filter criteria. By default, only OPEN parcels are returned. 
            <h3>Filters</h3>
            <p>
                All filters are optional. If value is not provided is null or empty, filter will not be applied. 
                Filters can be combined at will. 
                Use as query parameters.
            </p>
        
 * @summary Returns all parcels
 */
export const getParcels2 = <TData = AxiosResponse<ParcelTo[]>>(
    params: GetParcels2Params, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/parcels`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary List parcel subtractions
 */
export const getParcelSubtractionHistory = <TData = AxiosResponse<SubtractionResponse[]>>(
    parcelId: number,
    params: GetParcelSubtractionHistoryParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/parcels/${parcelId}/subtractions-history`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Delete restriction from parcels history
 */
export const deleteParcelSubtraction = <TData = AxiosResponse<void>>(
    parcelId: number,
    params: DeleteParcelSubtractionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/parcels/${parcelId}/subtractions-history`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Returns seed applications list with fertilization and revenues for each seed application.
 * @summary Parcel seeding history
 */
export const getSeedApplications1 = <TData = AxiosResponse<ParcelSeedApplicationTo[]>>(
    parcelId: number,
    params: GetSeedApplications1Params, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/parcels/${parcelId}/seed-applications`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Returns parcel predecessors.
 * @summary Parcel history
 */
export const getParcelPredecessors = <TData = AxiosResponse<ParcelPredecessorTo[]>>(
    parcelId: number,
    params: GetParcelPredecessorsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/parcels/${parcelId}/predecessors`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Returns list of parcel restriction and available subsidies from eAgri.
 * @summary Parcel eAgri info
 */
export const getParcelRestrictions = <TData = AxiosResponse<ParcelEagriInfoTo>>(
    parcelId: number,
    params: GetParcelRestrictionsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/parcels/${parcelId}/eagri`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Returns list of parcels suggested by filter criteria. By default, only OPEN parcels are returned. 
            <h3>Filters</h3>
            <p>
                All filters are optional. If value is not provided is null or empty, filter will not be applied. 
                Filters can be combined at will. 
                Use as query parameters.
            </p>
        
 * @summary Returns simplified parcel list
 */
export const getParcelSuggestions = <TData = AxiosResponse<ParcelSuggestionTo[]>>(
    params: GetParcelSuggestionsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/parcels/suggestions`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * The statistics include number of parcels and their total are, sown crops statistics and fertilization statistics.
 * @summary Farm statistics
 */
export const getParcelStatistics = <TData = AxiosResponse<FarmStatisticsTo>>(
    params: GetParcelStatisticsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/parcels/statistics`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Returns available land use.
 * @summary Get land use
 */
export const getLandUse = <TData = AxiosResponse<LandUseResponse[]>>(
    params?: GetLandUseParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/parcels/land-use`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getParcelCurrentSeedApplicationColors = <TData = AxiosResponse<ParcelCurrentSeedApplicationColorTo[]>>(
    params: GetParcelCurrentSeedApplicationColorsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/parcels/current-seed-application/colors`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns all centers
 */
export const getCenters = <TData = AxiosResponse<CenterTo[]>>(
    params: GetCentersParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/parcels/centers`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Returns a list of farms  current user is authorized to.
 * @summary Returns current user farms
 */
export const getUserFarms1 = <TData = AxiosResponse<FarmTo[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/farms`,options
    );
  }

/**
 * Returns detail of a farm user is authorized.
 * @summary Returns farm detail
 */
export const getFarmDetail1 = <TData = AxiosResponse<FarmTo>>(
    farmId: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/farms/${farmId}`,options
    );
  }

export const getFarmUpdates = <TData = AxiosResponse<FarmUpdateFlatResponse[]>>(
    params: GetFarmUpdatesParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/farms/updated`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns farm settings
 */
export const getFarmSettings = <TData = AxiosResponse<FarmSettingsTo>>(
    farmId: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/farms/settings/${farmId}`,options
    );
  }

/**
 * @deprecated
 */
export const getAccountUserFarms = <TData = AxiosResponse<InternalFarm[]>>(
    params: GetAccountUserFarmsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/farms/account`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Returns a farm name from eAGRI if user has already provided auth data (i.e. POST /eagri-auth went OK).
 */
export const eagriFarmName = <TData = AxiosResponse<EagriAuthValidationResultTo>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/eagri-auth/name`,options
    );
  }

/**
 * Returns detail of a farm user is authorized.
 * @deprecated
 * @summary Returns farm detail (legacy payload). Use /farms/{farmId} instead whenever possible.
 */
export const getLegacyFarmDetail = <TData = AxiosResponse<DeprecatedFarmTo>>(
    farmId: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/deprecated/farms/${farmId}`,options
    );
  }

export const searchCountries = <TData = AxiosResponse<CountryTo[]>>(
    params?: SearchCountriesParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/countries/supported`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary List farm catalogues
 */
export const getCatalogues = <TData = AxiosResponse<CatalogueTo[]>>(
    params: GetCataloguesParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Returns seed suggestion sorted by favorites on top
 * @summary Get seed suggestions
 */
export const getSeedSuggestions = <TData = AxiosResponse<CropSeedSuggestionTo[]>>(
    params: GetSeedSuggestionsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/seeds/suggestions`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getPlantProtectionDetail = <TData = AxiosResponse<PlantProtectionDetailTo>>(
    plantProtectionId: string,
    params: GetPlantProtectionDetailParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/plant-protections/${plantProtectionId}/detail`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getPlantProtectionDetailApplications = <TData = AxiosResponse<PlantProtectionDetailRegistrationApplicationTo[]>>(
    plantProtectionId: string,
    params: GetPlantProtectionDetailApplicationsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/plant-protections/${plantProtectionId}/detail/applications`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getPlantProtectionApplications = <TData = AxiosResponse<PlantProtectionApplicationTo[]>>(
    plantProtectionId: string,
    params: GetPlantProtectionApplicationsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/plant-protections/${plantProtectionId}/applications`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary List plant protection suggestions
 */
export const getPlantProtectionSuggestions = <TData = AxiosResponse<PlantProtectionSuggestionTo[]>>(
    params: GetPlantProtectionSuggestionsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/plant-protections/suggestions`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Returns farm plant protection products catalogue
 * @summary List plant protection products
 */
export const getPlantProtections = <TData = AxiosResponse<PlantProtectionOverviewTo[]>>(
    params: GetPlantProtectionsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/plant-protections/overview`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getBioFunction = <TData = AxiosResponse<string[]>>(
    params?: GetBioFunctionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/plant-protection/bio-function`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getFertilizerMineralIntake1 = <TData = AxiosResponse<FertilizerMineralIntakeResponse>>(
    fertilizerId: number,
    params: GetFertilizerMineralIntake1Params, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/fertilizers/${fertilizerId}/dose`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Returns fertilizers suggestions sorted by favorites on top
 * @summary Get fertilizer suggestions
 */
export const getFertilizerSuggestions = <TData = AxiosResponse<FertilizerSuggestionTo[]>>(
    params: GetFertilizerSuggestionsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/fertilizers/suggestions`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getEagriFertilizerNitrogenCategoryType = <TData = AxiosResponse<EnumEntriesEagriFertilizerNitrogenCategory>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/fertilizers/eagri-nitrogen-category`,options
    );
  }

export const getHarvestProducts = <TData = AxiosResponse<HarvestProductTo[]>>(
    params?: GetHarvestProductsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/eagri/harvest-products`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Režim registrace hnojiva
 */
export const getFertilizerRegistratinType = <TData = AxiosResponse<EagriFertilizerRegistrationTypeTo[]>>(
    params?: GetFertilizerRegistratinTypeParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/eagri/fertilizer-registration-type`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Číselník kategorie hnojiva z pohledu obsahu dusíku
 */
export const getFertilizerNitrogenCategory = <TData = AxiosResponse<EagriFertilizerNitrogenCategoryTo[]>>(
    params?: GetFertilizerNitrogenCategoryParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/eagri/fertilizer-nitrogen-categories`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Číselník druhů hnojiva
 */
export const getFertilizerTypes = <TData = AxiosResponse<EagriFertilizerKindTo[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/eagri/fertilizer-kind`,options
    );
  }

export const getCropUseTypes = <TData = AxiosResponse<CropUseTypeTo[]>>(
    params?: GetCropUseTypesParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/eagri/crop-use`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Returns available crop catalogue.
 * @summary List crops
 */
export const getCrops = <TData = AxiosResponse<CropTo[]>>(
    params: GetCropsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/catalogues/crops`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns all farm names, associated with gateway user IDs. Parameter can be farmId or name. If parameter is not provided, all farms with nonempty names are returned. Supports sorting
 */
export const getAllFarms = <TData = AxiosResponse<AdminFarmTo[]>>(
    params?: GetAllFarmsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/farms`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns all users associated with the given farm.
 */
export const getAllFarmUsers = <TData = AxiosResponse<AdminFarmUserTo[]>>(
    farmId: number,
    params?: GetAllFarmUsersParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/farms/${farmId}/users`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * 
             Returns preview of all data available at LPIS.
             
             NOTE:
             It is also possible to download eagri data of test accounts (preferrably from localhost).
             To do so, set eAgri.url property in agroevidence to https://eagritest.cz/ssl/nosso-app/EPO/WS/v2Online/vOKOsrv.ashx?SERVICEID=
                 and then call /auth/eagri-auth endpoint with credentials of the test account.
             Once credentials are stored in agr.lpis_auth_data, register a new farm (or manually assign LPIS auth to an existing farm if you know what you're doing).
             
 * @summary Returns detailed LPIS preview.
 */
export const getFullLpisDetail = <TData = AxiosResponse<EagriDpbPair[]>>(
    farmId: number,
    params?: GetFullLpisDetailParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/farms/${farmId}/lpis-full`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * @summary Returns eAgri services sync status
 */
export const getSyncStatus = <TData = AxiosResponse<EagriServiceSyncStatusTo[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/catalogues/eagri/sync`,options
    );
  }

/**
 * Returns all evidence actions for given farm.
 * @summary Returns all actions
 */
export const getActions = <TData = AxiosResponse<ActionOverviewTo[]>>(
    params: GetActionsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/actions`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const deleteActions = <TData = AxiosResponse<void>>(
    params: DeleteActionsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/actions`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getAction = <TData = AxiosResponse<GetAction200>>(
    actionId: number,
    params: GetActionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/actions/${actionId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const deleteAction = <TData = AxiosResponse<void>>(
    actionId: number,
    params: DeleteActionParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/actions/${actionId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const targetSeedApplicationApplications = <TData = AxiosResponse<TargetSeedApplicationActionsResponseTo>>(
    params: TargetSeedApplicationApplicationsParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/actions/target-seed-application`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const getActionTypes = <TData = AxiosResponse<ActionTypeTo[]>>(
    params?: GetActionTypesParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/actions/action-types`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const resetSeason = <TData = AxiosResponse<void>>(
    seasonId: number,
    params: ResetSeasonParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/sowing-plan/seasons/${seasonId}/reset`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const evictParcelChainCacheEvent = <TData = AxiosResponse<void>>(
    params?: EvictParcelChainCacheEventParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `https://agroevidence.cf-nightly.dev.cleverfarm.cz/api/admin/cache/parcel-chain`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export type UpdateCentersResult = AxiosResponse<InternalCenterTo[]>
export type GetDetailedParcelWithSowingPlanResult = AxiosResponse<ParcelWithSowingPlansTo[]>
export type PutCropsToParcelAndSeasonResult = AxiosResponse<void>
export type GetParcelSowingPlan1Result = AxiosResponse<SowingPlanSeasonCropTo[]>
export type PutCropToParcelAndSeasonResult = AxiosResponse<void>
export type GetEagriAuthResult = AxiosResponse<EagriAuthDataValidationResponseTo>
export type PutEagriAuthResult = AxiosResponse<EagriAuthDataValidationResponseTo>
export type UpdateFarmResult = AxiosResponse<void>
export type PutPlantProtectionResult = AxiosResponse<PlantProtectionDetailTo>
export type PatchPlantProtectionResult = AxiosResponse<void>
export type PutPlantProtectionRegistrationApplicationResult = AxiosResponse<void>
export type DeletePlantProtectionRegistrationApplicationResult = AxiosResponse<void>
export type GetFertilizerResult = AxiosResponse<FertilizerDetailTo>
export type PutFertilizerResult = AxiosResponse<FertilizerDetailTo>
export type HideFertilizerResult = AxiosResponse<FertilizerDetailTo>
export type UpdateSkeagisFarmResult = AxiosResponse<SkeagisFarmUpdateResult>
export type RecomputeFarmGeometriesResult = AxiosResponse<Unit[]>
export type OverwriteParcelGeometriesResult = AxiosResponse<ParcelGeometryOverwriteResult>
export type GetFarmDetail2Result = AxiosResponse<AdminFarmTo>
export type PutFarmResult = AxiosResponse<AdminFarmTo>
export type UpdateFarmCurrentParcelsResult = AxiosResponse<void>
export type UpdateAllFarmsCurrentParcelsResult = AxiosResponse<void>
export type UpdateFarmFromShapefileResult = AxiosResponse<unknown>
export type UpdateSkeagisStateResult = AxiosResponse<FarmIntegrationResponse>
export type CreateNewSkeagisIntegrationResult = AxiosResponse<FarmIntegrationResponse>
export type UpdateSowingActionResult = AxiosResponse<InternalActionIdResponse>
export type CreateSowingActionResult = AxiosResponse<InternalActionIdResponse>
export type UpdateOtherActionResult = AxiosResponse<InternalActionIdResponse>
export type CreateOtherActionResult = AxiosResponse<InternalActionIdResponse>
export type UpdateMowingActionResult = AxiosResponse<InternalActionIdResponse>
export type CreateMowingActionResult = AxiosResponse<InternalActionIdResponse>
export type UpdateHarvestActionResult = AxiosResponse<InternalActionIdResponse>
export type CreateHarvestActionResult = AxiosResponse<InternalActionIdResponse>
export type UpdateEPHActionResult = AxiosResponse<InternalActionIdResponse>
export type CreateEPHActionResult = AxiosResponse<InternalActionIdResponse>
export type GetPlantProtectionsInternalResult = AxiosResponse<InternalMaterialTo[]>
export type GetParcelIntersectionsResult = AxiosResponse<InternalParcelTo[]>
export type GetActionInfoResult = AxiosResponse<InternalActionInfo[]>
export type CreateDraftActionResult = AxiosResponse<InternalActionIdResponse>
export type GetSeasonsResult = AxiosResponse<SowingPlanSeasonTo[]>
export type CreateMissingSeasonsResult = AxiosResponse<SowingPlanSeasonTo[]>
export type SendPorUserReportToEagriResult = AxiosResponse<PlantProtectionPairingResultTo>
export type GetEagriPorUseReportBodyResult = AxiosResponse<PorUseBody>
export type SendFertilizerUserReportToEagriResult = AxiosResponse<EagriReportResponse>
export type GetFertilizerUserReportBodyResult = AxiosResponse<FertiliserUseBody>
export type RegisterWithShapeFileResult = AxiosResponse<unknown>
export type ValidateShapefileResult = AxiosResponse<unknown>
export type RegisterEmptyFarmResult = AxiosResponse<unknown>
export type RegisterWithEagriResult = AxiosResponse<unknown>
export type GetParcelsSubtractionResult = AxiosResponse<SubtractionTo>
export type GetZonesResult = AxiosResponse<ZoneDetailTo[]>
export type CreateZoneResult = AxiosResponse<ZoneDetailTo>
export type RecountSubtractionsForActionResult = AxiosResponse<ParcelSubtractionResponse[]>
export type GetParcelSubtractionGeometryResult = AxiosResponse<ParcelGeometryResponse>
export type GetParcelAreaAfterSubtractionResult = AxiosResponse<ParcelAreaTo[]>
export type CreateResult = AxiosResponse<CustomParcelCreateResult>
export type GetAvailableUpdatesResult = AxiosResponse<FarmUpdateChangesResponse>
export type RequestAvailableUpdatesResult = AxiosResponse<void>
export type StoreAuthDataResult = AxiosResponse<EagriAuthValidationResultTo>
export type GetSeedsResult = AxiosResponse<CropSeedTo[]>
export type PostSeedResult = AxiosResponse<CropSeedTo>
export type PostPlantProtectionResult = AxiosResponse<PlantProtectionDetailTo>
export type CopyPlantProtectionResult = AxiosResponse<PlantProtectionDetailTo>
export type PostPlantProtectionRegistrationApplicationResult = AxiosResponse<void>
export type GetOrganismsResult = AxiosResponse<OrganismTo[]>
export type CreateOrganismsResult = AxiosResponse<OrganismTo>
export type GetActiveSubstancesResult = AxiosResponse<ActiveSubstanceTo[]>
export type PostActiveSubstanceResult = AxiosResponse<ActiveSubstanceTo>
export type GetFertilizersResult = AxiosResponse<FertilizerTo[]>
export type PostFertilizerResult = AxiosResponse<FertilizerDetailTo>
export type ImportSurfaceWaterProtectionZonesResult = AxiosResponse<void>
export type ImportGroundWaterProtectionZonesResult = AxiosResponse<void>
export type MigrateStoreIdsResult = AxiosResponse<void>
export type SyncCountiesResult = AxiosResponse<void>
export type RepairSeedApplicationsResult = AxiosResponse<void>
export type UpdateNitrateVulnerableResult = AxiosResponse<AdminFarmManagementTo[]>
export type GetNitrateVulnerableOnFarmResult = AxiosResponse<AdminNitrateVulnerableZoneTo[]>
export type PostNitrateVulnerableOnFarmResult = AxiosResponse<AdminNitrateVulnerableZoneTo[]>
export type SatelliteUpdateSyncResult = AxiosResponse<void>
export type MoveParcelValiditiesToNewestSowingResult = AxiosResponse<AdminParcelValidityMoveResult>
export type MoveActionsAfterUpdateResult = AxiosResponse<AdminActionMoveResult>
export type CreateTestEagriFarmResult = AxiosResponse<void>
export type RefreshFarmCacheResult = AxiosResponse<void>
export type GetLpisBlocksResult = AxiosResponse<unknown>
export type UpdateEagriFarmResult = AxiosResponse<unknown>
export type DeleteNonTerminalActionSeedAppsResult = AxiosResponse<void>
export type AssignCorrectSeedAppsToAllParcelsActionParcelsResult = AxiosResponse<void>
export type AddSowingPlanToCurrentSeasonResult = AxiosResponse<void>
export type MoveAllInitialSeedAppsToStartResult = AxiosResponse<void>
export type FixEagriActionCropsResult = AxiosResponse<void>
export type PostSyncResult = AxiosResponse<void>
export type GetSeedApplicationFertilizationResult = AxiosResponse<ParcelAccountableNitrogenTo[]>
export type SplitActionResult = AxiosResponse<SplitActionResponse>
export type ValidateEPHActionResult = AxiosResponse<ValidationResponseTo>
export type GetParcelResult = AxiosResponse<ParcelDetailTo>
export type PatchParcelResult = AxiosResponse<void>
export type DeleteZoneResult = AxiosResponse<void>
export type UpdateZoneResult = AxiosResponse<ZoneDetailTo>
export type GetSeedResult = AxiosResponse<CropSeedTo>
export type PatchSeedResult = AxiosResponse<CropSeedTo>
export type GetParcelByArealNameResult = AxiosResponse<PublicParcelTo[]>
export type GetCropsInternalResult = AxiosResponse<InternalMaterialTo[]>
export type GetParcelSowingPlanResult = AxiosResponse<InternalSeasonTo[]>
export type GetParcelsResult = AxiosResponse<InternalParcelTo[]>
export type GetParcelDetailResult = AxiosResponse<InternalParcelTo>
export type GetSeedApplicationsResult = AxiosResponse<InternalSeedApplicationTo[]>
export type GetFertilizersInternalResult = AxiosResponse<InternalMaterialTo[]>
export type GetFertilizerMineralIntakeResult = AxiosResponse<InternalFertilizerMineralIntakeResponse>
export type GetFarmDetailResult = AxiosResponse<InternalFarmResponse>
export type GetFarmUsersResult = AxiosResponse<InternalFarmUserResponse[]>
export type GetUserFarmsResult = AxiosResponse<InternalFarmResponse[]>
export type GetCropsInternal1Result = AxiosResponse<InternalCropTo[]>
export type GetCropInternalResult = AxiosResponse<InternalCropTo>
export type GetCropCatalogueTypeResult = AxiosResponse<CropCatalogueType>
export type GetSowingPlanStatisticsResult = AxiosResponse<SowingPlanSeasonStatisticsTo>
export type GetSeasonsExcelResult = AxiosResponse<Blob>
export type GetSeedApplicationsAtDateResult = AxiosResponse<SeedApplicationTo[]>
export type GetSeededAreasResult = AxiosResponse<string>
export type GetSeedApplicationsReportResult = AxiosResponse<string>
export type GetRevenuesReportResult = AxiosResponse<string>
export type GetRestrictionsSowingReportResult = AxiosResponse<ValidationResponseTo>
export type GetPorUsageReportResult = AxiosResponse<string>
export type GetPorConsumptionByPPReportResult = AxiosResponse<string>
export type GetPorConsumptionByCropReportResult = AxiosResponse<string>
export type GetParcels1Result = AxiosResponse<Blob>
export type GetReportsResult = AxiosResponse<ReportHistoryTo[]>
export type GetFertilizerUsageReportResult = AxiosResponse<string>
export type GetFertilizerOrganicReportResult = AxiosResponse<string>
export type GetFertilizerConsumptionReportResult = AxiosResponse<string>
export type GetFertilizerConsumptionByCropReportResult = AxiosResponse<string>
export type GetFarmNitrogenReportResult = AxiosResponse<string>
export type GetCvsReportResult = AxiosResponse<HttpServletResponse>
export type GetEagriPorUseErrorsResult = AxiosResponse<string>
export type CheckFertilizerUserReportResult = AxiosResponse<Response>
export type GetActionsReportResult = AxiosResponse<string>
export type GetParcels2Result = AxiosResponse<ParcelTo[]>
export type GetParcelSubtractionHistoryResult = AxiosResponse<SubtractionResponse[]>
export type DeleteParcelSubtractionResult = AxiosResponse<void>
export type GetSeedApplications1Result = AxiosResponse<ParcelSeedApplicationTo[]>
export type GetParcelPredecessorsResult = AxiosResponse<ParcelPredecessorTo[]>
export type GetParcelRestrictionsResult = AxiosResponse<ParcelEagriInfoTo>
export type GetParcelSuggestionsResult = AxiosResponse<ParcelSuggestionTo[]>
export type GetParcelStatisticsResult = AxiosResponse<FarmStatisticsTo>
export type GetLandUseResult = AxiosResponse<LandUseResponse[]>
export type GetParcelCurrentSeedApplicationColorsResult = AxiosResponse<ParcelCurrentSeedApplicationColorTo[]>
export type GetCentersResult = AxiosResponse<CenterTo[]>
export type GetUserFarms1Result = AxiosResponse<FarmTo[]>
export type GetFarmDetail1Result = AxiosResponse<FarmTo>
export type GetFarmUpdatesResult = AxiosResponse<FarmUpdateFlatResponse[]>
export type GetFarmSettingsResult = AxiosResponse<FarmSettingsTo>
export type GetAccountUserFarmsResult = AxiosResponse<InternalFarm[]>
export type EagriFarmNameResult = AxiosResponse<EagriAuthValidationResultTo>
export type GetLegacyFarmDetailResult = AxiosResponse<DeprecatedFarmTo>
export type SearchCountriesResult = AxiosResponse<CountryTo[]>
export type GetCataloguesResult = AxiosResponse<CatalogueTo[]>
export type GetSeedSuggestionsResult = AxiosResponse<CropSeedSuggestionTo[]>
export type GetPlantProtectionDetailResult = AxiosResponse<PlantProtectionDetailTo>
export type GetPlantProtectionDetailApplicationsResult = AxiosResponse<PlantProtectionDetailRegistrationApplicationTo[]>
export type GetPlantProtectionApplicationsResult = AxiosResponse<PlantProtectionApplicationTo[]>
export type GetPlantProtectionSuggestionsResult = AxiosResponse<PlantProtectionSuggestionTo[]>
export type GetPlantProtectionsResult = AxiosResponse<PlantProtectionOverviewTo[]>
export type GetBioFunctionResult = AxiosResponse<string[]>
export type GetFertilizerMineralIntake1Result = AxiosResponse<FertilizerMineralIntakeResponse>
export type GetFertilizerSuggestionsResult = AxiosResponse<FertilizerSuggestionTo[]>
export type GetEagriFertilizerNitrogenCategoryTypeResult = AxiosResponse<EnumEntriesEagriFertilizerNitrogenCategory>
export type GetHarvestProductsResult = AxiosResponse<HarvestProductTo[]>
export type GetFertilizerRegistratinTypeResult = AxiosResponse<EagriFertilizerRegistrationTypeTo[]>
export type GetFertilizerNitrogenCategoryResult = AxiosResponse<EagriFertilizerNitrogenCategoryTo[]>
export type GetFertilizerTypesResult = AxiosResponse<EagriFertilizerKindTo[]>
export type GetCropUseTypesResult = AxiosResponse<CropUseTypeTo[]>
export type GetCropsResult = AxiosResponse<CropTo[]>
export type GetAllFarmsResult = AxiosResponse<AdminFarmTo[]>
export type GetAllFarmUsersResult = AxiosResponse<AdminFarmUserTo[]>
export type GetFullLpisDetailResult = AxiosResponse<EagriDpbPair[]>
export type GetSyncStatusResult = AxiosResponse<EagriServiceSyncStatusTo[]>
export type GetActionsResult = AxiosResponse<ActionOverviewTo[]>
export type DeleteActionsResult = AxiosResponse<void>
export type GetActionResult = AxiosResponse<GetAction200>
export type DeleteActionResult = AxiosResponse<void>
export type TargetSeedApplicationApplicationsResult = AxiosResponse<TargetSeedApplicationActionsResponseTo>
export type GetActionTypesResult = AxiosResponse<ActionTypeTo[]>
export type ResetSeasonResult = AxiosResponse<void>
export type EvictParcelChainCacheEventResult = AxiosResponse<void>
